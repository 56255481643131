import React from 'react'

const PatientFormMain = () => {
  return (
    <>
    <div id='website-responsive' className='d-block d-md-none d-xl-block'>
    <div className='bg-img3 financial-text-padding' style={{ backgroundImage: 'url("/patient-form/Patients-Form-Banner.webp")', backgroundPosition:'left', height:'100%', width:'100%' }}>
        <div className='financial-max-width text-align-center '>
            <h1 style={{ }} className='fs-ss-46 text-oswald fw-900 lh-1-2 text-black'>
                <>
                    Patient's Forms
                </>
            </h1>
         </div>
    </div>
</div>    
<div id='website-responsive' className='d-none d-md-block d-xl-none'>
    <div className='bg-img3 text-end py-5 pe-5' style={{ backgroundImage: 'url("/patient-form/Patients-Form-Banner.webp")', backgroundPosition:'left', height:'100%', width:'100%' }}>
        <div className='py-5 pe-5'>
            <h1 style={{ }} className='fs-ss-50 text-oswald fw-900 lh-1-2 text-black'>
                    Patient's Forms
            </h1>
         </div>
    </div>
</div>
</>
  )
}

export default PatientFormMain