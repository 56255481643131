import React from 'react'
import { Col, Row } from 'reactstrap'

const PsychiatryTreatment = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding6rem right-padding6rem p-top-bottom2rem2' style={{ backgroundColor:'#f1f1f1'}}>
            <div className=''>
                <h3 className='fs-ss-42 text-raleway fw-700'>
                    <center>
                        CONDITIONS WE TREAT
                    </center>
                </h3>
            </div>

            <div className=' mb-md-5 mb-4'>
                <h3 className='fs-ss-32 fw-700 text-raleway text-ss-primary lh-1-2'>
                    <center>
                        What Can We Help You With Today?
                    </center>
                </h3>
            </div>

            <div className=''>
                <Row className='justify-content-center'>
                    <Col md='11' xl='4' className='mt-3 mt-md-0' >
                        <div className='p-3 py-4' style={{ backgroundColor:'#f8f8f8', boxShadow:'1px 2px 6px 0 rgba(0,0,0,.2)', margin:'10px', borderRadius:'0px'}}>
                            <center>
                                <div className=''>
                                    <img src='/psych-visit/Valley-Psychiatry-icon1.webp' alt='psych' height='85px' width='85px' />
                                </div> 

                                <div className='pt-3'>
                                    <h4 className='fs-ss-26-2 fw-600 text-roboto'>Depression</h4>
                                </div>  

                                <div className='px-1'>
                                    <p className='fw-400 fs-ss-15 text-roboto lh-1-8'>Depression can happen at any age. Persistent sadness, anxiety, or “empty” mood or feelings of hopelessness and pessimism greatly disrupt daily life routines.</p>
                                </div> 
                            </center>
                        </div>
                    </Col>

                    <Col md='11' xl='4' className='mt-3 mt-md-0' >
                        <div className='p-3 py-4' style={{ backgroundColor:'#f8f8f8', boxShadow:'1px 2px 6px 0 rgba(0,0,0,.2)', margin:'10px', borderRadius:'0px'}}>
                            <center>
                                <div className=''>
                                    <img src='/psych-visit/Valley-Psychiatry-icon2.webp' alt='psych' height='85px' width='85px' />
                                </div> 

                                <div className='pt-3'>
                                    <h4 className='fs-ss-26-2 fw-600 text-roboto'>Anxiety</h4>
                                </div>  

                                <div className='px-1'>
                                    <p className='fw-400 fs-ss-15 text-roboto lh-1-8'>
                                        Anxiety disorders are like other forms of mental illness that generate feelings of nervousness, panic and fear as well as sweating and a rapid heartbeat. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                    </p>
                                </div> 
                            </center>
                        </div>
                    </Col>

                    <Col md='11' xl='4' className='mt-3 mt-md-0' >
                        <div className='p-3 py-4' style={{ backgroundColor:'#f8f8f8', boxShadow:'1px 2px 6px 0 rgba(0,0,0,.2)', margin:'10px', borderRadius:'0px'}}>
                            <center>
                                <div className=''>
                                    <img src='/psych-visit/Valley-Psychiatry-icon3.webp' alt='psych' height='85px' width='85px' />
                                </div> 

                                <div className='pt-3'>
                                    <h4 className='fs-ss-26-2 fw-600 text-roboto'>Bipolar Disorder</h4>
                                </div>  

                                <div className='px-3'>
                                    <p className='fw-400 fs-ss-15 text-roboto lh-1-8'>
                                        This disorder is a mental illness that causes unusual shifts in mood, energy, activity levels, concentration that impact the ability to carry out day-to-day tasks.
                                    </p>
                                </div> 
                            </center>
                        </div>
                    </Col>
                </Row>

                <Row className='justify-content-center'>
                    <Col md='11' xl='4' className='mt-3 mt-md-0'  style={{ }}>
                        <div className='p-3 py-4' style={{ backgroundColor:'#f8f8f8', boxShadow:'1px 2px 6px 0 rgba(0,0,0,.2)', margin:'10px', borderRadius:'0px', }}>
                            <center>
                                <div className=''>
                                    <img src='/psych-visit/Valley-Psychiatry-icon4.webp' alt='psych' height='85px' width='85px' />
                                </div> 

                                <div className='pt-3'>
                                    <h4 className='fs-ss-26-2 fw-600 text-roboto'>ADHD</h4>
                                </div>  

                                <div className='px-3'>
                                    <p className='fw-400 fs-ss-15 text-roboto lh-1-8'>
                                        One of the most common mental disorders affecting children is Attention-deficit/hyperactivity disorder. Symptoms include inattention, hyperactivity and impulsivity.
                                    </p>
                                </div> 
                            </center>
                        </div>
                    </Col>

                    <Col md='11' xl='4' className='mt-3 mt-md-0'  style={{  }}>
                        <div className='p-3 py-4' style={{ backgroundColor:'#f8f8f8', boxShadow:'1px 2px 6px 0 rgba(0,0,0,.2)', margin:'10px', borderRadius:'0px', }}>
                            <center>
                                <div className=''>
                                    <img src='/psych-visit/Valley-Psychiatry-icon5.webp' alt='psych' height='85px' width='85px' />
                                </div> 

                                <div className='pt-3'>
                                    <h4 className='fs-ss-26-2 fw-600 text-roboto'>Mood Disorders</h4>
                                </div>  

                                <div className='px-2'>
                                    <p className='fw-400 fs-ss-15 text-roboto lh-1-8'>
                                        Anyone can feel sad or depressed but mood disorders are generally more intense and harder to manage than normal feelings. Certain life events and stress can expose or worsen the condition.
                                    </p>
                                </div> 
                            </center>
                        </div>
                    </Col>
                    
                    <Col md='11' xl='4' className='mt-3 mt-md-0' >
                        <div className='p-3 py-4' style={{ backgroundColor:'#f8f8f8', boxShadow:'1px 2px 6px 0 rgba(0,0,0,.2)', margin:'10px', borderRadius:'0px'}}>
                            <center>
                                <div className=''>
                                    <img src='/psych-visit/Valley-Psychiatry-icon6.webp' alt='psych' height='85px' width='85px' />
                                </div> 

                                <div className='pt-3'>
                                    <h4 className='fs-ss-26-2 fw-600 text-roboto'>Eating Disorders</h4>
                                </div>  

                                <div className='px-3'>
                                    <p className='fw-400 fs-ss-15 text-roboto lh-1-8'>
                                        These behavioural issues are defined by persistent disturbances in eating habits, as well as distressing thoughts and feelings that impact social, psychological, and physical function.
                                    </p>
                                </div> 
                            </center>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default PsychiatryTreatment