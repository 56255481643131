import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import PsychiatryMain from '../components/psychiatry/PsychiatryMain'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import PsychiatryMentalHealth from '../components/psychiatry/PsychiatryMentalHealth'
import PsychiatryNurses from '../components/psychiatry/PsychiatryNurses'
import PsychiatryMindAndBody from '../components/psychiatry/PsychiatryMindAndBody'
import PsychiatryHolisticApproach from '../components/psychiatry/PsychiatryHolisticApproach'
import PsychiatryOptimalHealth from '../components/psychiatry/PsychiatryOptimalHealth'
import PsychiatryTreatment from '../components/psychiatry/PsychiatryTreatment'
import PsychiatryHopeAndHealing from '../components/psychiatry/PsychiatryHopeAndHealing'
import PsychiatryCallNow from '../components/psychiatry/PsychiatryCallNow'
import PsychiatryPeaceOfMind from '../components/psychiatry/PsychiatryPeaceOfMind'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const PsychiatryPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>

        <HelmetProvider>
            <Helmet>
            <title>Best Pediatric Mental Health Care in Guntersville &ndash; Valley Pediatrics</title>
            <meta name="description" content="Get your children the best pediatric mental healthcare in Guntersville with Valley Pediatrics to give them the best chance of a fulfilled life." />
            <link rel="canonical" href="https://www.valley-pediatrics.com/valley-psychiatry/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Best Pediatric Mental Health Care in Guntersville &ndash; Valley Pediatrics" />
            <meta property="og:description" content="Get your children the best pediatric mental healthcare in Guntersville with Valley Pediatrics to give them the best chance of a fulfilled life." />
            <meta property="og:url" content="https://www.valley-pediatrics.com/valley-psychiatry/" />
            <meta property="og:site_name" content="Valley Pediatrics" />
            <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
            <meta property="article:modified_time" content="2023-05-08T09:36:28+00:00" />
            <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="13 minutes" />
            </Helmet>
        </HelmetProvider>
        
        <TopHeader/>
        <MainHeader/>

        <PsychiatryMain/>
        <PsychiatryMentalHealth/>
        <PsychiatryNurses/>
        <PsychiatryMindAndBody/>
        <PsychiatryHolisticApproach/>
        <PsychiatryOptimalHealth/>
        <PsychiatryTreatment/>
        <PsychiatryHopeAndHealing/>
        <PsychiatryCallNow/>
        <PsychiatryPeaceOfMind/>


        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default PsychiatryPage