import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Icon1 from '../../../assets/images/ads/Icon1.png'
import Icon2 from '../../../assets/images/ads/Icon2.png'
import Icon3 from '../../../assets/images/ads/Icon3.png'


const ArabChildCare = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5'>
          <div className='pt-3 pb-2'>
                <text className='fs-ss-32-1 text-raleway fw-700 lh-1 text-ss-black'>
                    Your Best Choice for Child Care Near Arab
                </text>  
          </div>

          <div className=''>
                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                    <Link style={{ textDecoration:'none'}} className='text-ss-primary' to='/' >Valley Pediatrics </Link> is a family pediatric clinic located at a 15-minute drive from Arab. The top pediatricians and medical professionals make up our care team; they are enthusiastic about ensuring that kids stay well so they may play, grow, study, and fulfill their ambitions. For this, our pediatricians and nurses make sure kids receive the all-encompassing care they require to grow.
                </text>  
          </div>

          <div className=''>
                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                    Whether it’s an emergency visit for a sick child or a routine well-child checkup, we always find a time that works for you. We adhere to the “no patients waiting for appointments” policy and offer prompt, convenient access to top-notch pediatric treatment. When it comes to your child’s health and well being, we think it’s important to combine clinical knowledge with a composed, consoling approach.
                </text>  
          </div>


          <div className='pt-5 pb-3 '>
                <text className='fs-ss-32-2 text-raleway fw-700 text-ss-black lh-1-2'>
                    Delivering Care on Every Step
                </text>  
          </div>

          <div className='pb-2'>
                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                    You can get the best care and a chance to network with the top healthcare specialists all under one roof at Valley Pediatrics. We are here to make healthcare more accessible, practical, and dependable for you at every stage of your path toward better health.
                </text>  
          </div>

          <div className='py-5'>
                <Row className=''>
                    <Col sm='12' md='4' className='pb-4'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon1} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Same Day Sick Visits</text>
                        </div>
                    </Col>

                    <Col sm='12' md='4' className='py-4 py-md-0'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon2} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Most Insurances Accepted</text>
                        </div>
                    </Col>

                    <Col sm='12' md='4' className='py-4 py-md-0'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon3} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Accepting New Patients</text>
                        </div>
                    </Col>
                </Row>
            </div>


        </div>
    </div>
  )
}

export default ArabChildCare