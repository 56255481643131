import React from 'react'

const SickVisitsMain = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img about-text-padding' style={{ backgroundImage: 'url("/sick-visit/Sick-Visit-Banner-v1.webp")', height:'100%', width:'100%' }}>
            <div className='about-max-width'>
                <h1 className='fs-ss-44 text-oswald fw-800 lh-1 text-black'>
                    Sick Visits In Guntersville
                </h1>
             </div>
        </div>
    </div>
  )
}

export default SickVisitsMain