import React from 'react'

const BoazLocation = () => {
  return (
    <div>
        <div className=''>
        <div className='elementor-custom-embed' style={{ marginBottom:'-6px'}}>
            {/* [um-iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d54438.89465737263!2d74.27791584142051!3d31.484837283482378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d31.508994299999998!2d74.34483929999999!4m5!1s0x391901677786d497%3A0xf130ea67c5d5a58e!2shorizon%20med%20ped%20clinic!3m2!1d31.458783999999998!2d74.2875911!5e0!3m2!1sen!2s!4v1689224124038!5m2!1sen!2s" width="100px" height="100px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"] */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3294.1996964518066!2d-86.3046718!3d34.3453954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8889f934d22a25b9%3A0x88839cfbc9bb566c!2sValley%20Medical!5e0!3m2!1sen!2s!4v1691422792929!5m2!1sen!2s" width="100%" height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>            
        </div>
        </div>
    </div>
  )
}

export default BoazLocation