import React from 'react'
import Banner1 from '../../assets/images/home/Home-Slider-pic-1-v1.webp'
import Banner2 from '../../assets/images/home/Home-Slider-pic-2-v1.webp'

const HomeBanner = () => {  
  return (
    <>
    <div id="carouselExampleInterval" className="carousel slide text-center text-md-start pb-3" data-bs-ride="carousel">
  <div id='website-responsive' className="carousel-inner">
  <div className="carousel-item active home-banner-1 py-5 py-md-0" data-bs-interval="10000">
   <div className='row justify-content-center py-xl-5 my-xl-5 pt-md-5 pb-5 mb-5'>
    <div className="col-9 ps-3 pb-5 mb-5 py-xl-5 my-xl-5">
    <text style={{ textTransform:'uppercase',letterSpacing:'' }} className='text-black fs-ss-31-1 text-raleway fw-800 lh-1-2'>Treating the little with <br className='d-none d-md-block'/> a whole lot of <span className='text-ss-primary fs-ss-31-1'> care </span></text>
         <div style={{ paddingLeft:''}} className='flex-center1 mb-2 mb-md-0'>
          <a href='tel:+12562021111'>
          <button  style={{ boxShadow:'rgba(0, 0, 0, 0.25) 0px 4px 15px 0px', padding:'4px 35px'}} className='btn-ss-secondary mt-3 fs-ss-15 text-poppins rounded20'>
              <text className='fs-ss-19 text-roboto fw-500'>
                  (256) 202 1111
              </text>
          </button>
          </a>
          </div>
   </div>
    </div>
    </div>
    <div className="carousel-item active home-banner-2 py-5 py-md-0" data-bs-interval="10000">
   <div className='row justify-content-center py-xl-5 my-xl-5 pt-md-5 pb-5 mb-5'>
    <div className="col-9 ps-3 pb-5 mb-5 py-xl-5 my-xl-5">
    <text style={{ textTransform:'uppercase',letterSpacing:'' }} className='text-black fs-ss-31-1 text-raleway fw-800 lh-1-2'>Dealing with little <br className='d-none d-md-block'/> <span className='text-ss-primary fs-ss-31-1'> miracles </span> every day</text>
         <div style={{ paddingLeft:''}} className='flex-center1 pb-4 mb-3 pb-md-0 mb-md-0'>
          <a href='tel:+12562021111'>
          <button  style={{ boxShadow:'rgba(0, 0, 0, 0.25) 0px 4px 15px 0px', padding:'4px 35px'}} className='btn-ss-secondary mt-3 fs-ss-15 text-poppins rounded20'>
              <text className='fs-ss-19 text-roboto fw-500'>
                  (256) 202 1111
              </text>
          </button>
          </a>
          </div>
   </div>
    </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </>
  )
}

export default HomeBanner