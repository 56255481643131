import React from 'react'
import { Col, Row } from 'reactstrap'
import Icon1 from '../../assets/images/ads/Img1.png'
import Icon2 from '../../assets/images/ads/Img2.png'
import Icon3 from '../../assets/images/ads/Img3.png'
import Img1 from '../../assets/images/ads/Pediatric-Healthcare-services-bg.webp'

const AdsPageOurServices = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding8rem1 right-padding8rem1 py-md-5' style={{ }}>
            <div className=''>
                <text className='fs-ss-36 text-roboto-con fw-700'>
                    <center>
                        OUR SERVICES
                        <hr className='hr-style' style={{ width:'100px', marginTop:'0px', marginBottom:'4px'}}/>
                    </center>
                </text>
            </div>

            <div className='pt-md-5 pt-4'>
            <Row className='justify-content-center' style={{ gap:'' }}>
                <Col md='10' xl='4' className=' ' >
                    <div className='py5 rounded30 m-3' style={{ backgroundColor:'#e4e6e6', padding:'50px 38px 58px 38px'}}>
                        <div className=''>
                        <center>
                            <img src={Icon1} alt='sick visit' height='auto' width='80px' />
                        </center>
                        </div>

                        <div className='flex-cenr py-2'>
                            <text className='fs-ss-26-4 text-ralewat fw-700 text-ss-primary' style={{ letterSpacing:'1px'}}>
                                <center>SICK VISIT</center>
                            </text>
                        </div>

                        <div className='flex-cenr'>
                            <text className='fs-ss-14-3 text-roboto fw-500 lh-1-8'>
                                <center>
                                    In all their fun-loving glory, children tend to contract all forms of germs throughout the day, and these germs can often make them sick enough to need attentive care from a pediatric healthcare provider, which is where we come in with our focus on comfort and accuracy.
                                </center>
                            </text>
                        </div>
                    </div>
                </Col>

                <Col md='10' xl='4' className=' mx-md-'>
                    <div className='rounded30  m-3'  style={{ backgroundColor:'#e4e6e6', padding:'43px 27px 60px 27px'}}> 
                        <div className=''>
                        <center>
                            <img src={Icon2} alt='sick visit' height='auto' width='65px' />
                        </center>
                        </div>

                        <div className='py-2'>
                            <text className='fs-ss-26-4 text-ralewat fw-700 text-ss-primary' style={{ letterSpacing:'1px'}}>
                                <center>WELL VISITS</center>
                            </text>
                        </div>

                        <div className=''>
                            <text className='fs-ss-14-3 text-roboto fw-500 lh-1-8'>
                                <center>
                                    Even healthy, there is a lot to be concerned about with kids, like whether their growth rate is appropriate for their age and what should be regulated to ensure that their development is not being stunted. Our pediatric practitioners monitor the development according to the kid's age to ensure everything is on track.
                                </center>
                            </text>
                        </div>
                    </div>
                </Col>

                <Col md='10' xl='4' className=' mx-md-' >
                    <div className='rounded30 py5 m-3' style={{ backgroundColor:'#e4e6e6', padding:'43px 13px 110px 13px'}}>
                        <div className=''>
                        <center>
                            <img src={Icon3} alt='sick visit' height='auto' width='100px' />
                        </center>
                        </div>

                        <div className='py-2'>
                            <text className='fs-ss-26-4 text-ralewat fw-700 text-ss-primary' style={{ letterSpacing:'1px'}}>
                                <center>PSYCHIATRY</center>
                            </text>
                        </div>

                        <div className='px-3'>
                            <text className='fs-ss-14-3 text-roboto fw-500 lh-1-8'>
                                <center>
                                     Children cannot be truly healthy if the focus is only on their physical care while their mental health is suffering. To ensure young children's mental development and wellness, we offer psychiatric services.
                                </center>
                            </text>
                        </div>
                    </div>
                </Col>
            </Row>

            

            </div>
        </div>
        <div className='display-none'>
                <img src={Img1} alt='bg' width='100%' style={{ position:'relative', zIndex:'-1000', padding:'0rem 4rem', marginTop:'-21rem' }}  />
            </div>
    </div>
  )
}

export default AdsPageOurServices