import React from 'react'

const PsychiatryMain = () => {
  return (
    <>
    <div id='website-responsive' className='d-block d-md-none d-xl-block'>
        <div className='bg-img4 psychiatry-text-padding' style={{ backgroundImage: 'url("/psych-visit/Valley-Psychiatry-Banner-v1.webp")', height:'100%', width:'100%' }}>
            <div className='psychiatry-max-width'>
                <h1 className='fs-ss-44 text-oswald fw-800 lh-1 text-black'>
                Valley Psychiatry In Guntersville
                </h1>
             </div>
        </div>
    </div>
        <div id='website-responsive' className='d-none d-md-block d-xl-none'>
        <div className='bg-img4 py-5 ps-5' style={{ backgroundImage: 'url("/psych-visit/Valley-Psychiatry-Banner-v1.webp")', height:'100%', width:'100%' }}>
            <div className='psychiatry-max-width py-5 my-5'>
                <h1 className='fs-ss-44 text-oswald fw-800 lh-1 text-black'>
                Valley Psychiatry<br /> In Guntersville
                </h1>
             </div>
        </div>
    </div>
    </>
  )
}

export default PsychiatryMain