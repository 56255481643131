import React, { useEffect }  from 'react'
import { Calendar } from 'react-bootstrap-icons'
import { Card, Col, Row } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
// import BlogsTopicPrimaryMain from './blog-topics/primary-care.jsx/BlogsTopicPrimaryMain'
import axios from 'axios'

const BlogsTopics = () => { 
    const [blogs, setBlogs] = React.useState([])


    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_API_URL}/blogs`).then(res => {
    //         if (res.data.blogs.length > 0) {
    //             setBlogs(res.data.blogs)
    //         } else {
    //             setBlogs([])
    //         }
    //     }).catch(err => {

    //         console.log(err)

    //     })
    //     // .finally (() => {

    //     //     setContentLoading(false)

    //     // })

    // }, [])

    // get all blogs from blogs.json file  
    useEffect(() => {
        // read json file
        axios.get('/blogs/blogs.json').then(res => {
            if (res.data) {
                // set blogs to state
                setBlogs(res.data)
            }
        }
        ).catch(err => {
            console.log(`error`, err);
            // console.log('error')
        })
    },[])

            
  return (
    <div id='website-responsive'>
        {blogs?.map((blog) => {
                return (
                    <div className='left-padding7rem right-padding7rem my-md-5 my-3 mb-3'>
                        <Card className='padding3xem mb-4 ' style={{ boxShadow:'9.899px 9.899px 30px 0 rgba(0,0,0,.1)', border:'none' }}>
                            <Row className=' gy-4'>
                                <Col md='11' xl='6' >
                                    <img src={`/blogs/${blog?.featured_image}`} alt={blog?.title} width='100%' style={{ boxShadow:'8px 10px 20px 0px rgba(0, 0, 0, 0.35)', borderRadius:'1rem' }} />
                                </Col>
                                <Col md='11' xl='6' className=''>
                                <div className='ps-md-2' >
                                    <div className='mt-md-0 mt-4'>
                                        <Calendar className='me-2 text-ss-black'/>
                                        <text className='text-ss-black'> {moment(blog?.created_at).format('MMMM DD, YYYY')}</text>    
                                    </div>
                                    <div className='my-3'>
                                    <h2 className='fs-ss-28 fw-800'>
                                    <Link to={`/blogs/${blog?.slug}`} style={{ textDecoration:'none'}} className='text-black text-raleway fw-900 fs-ss-26-1'> {blog?.title} </Link>
                                    </h2>
                                    </div>
                                    <div className='py-1 py-md-0'>
                                        <text className='fs-ss-16 text-poppins lh-1-8'>
                                        {blog?.description}
                                        </text>
                                    </div>
                                </div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                )
            })}

        {/* <div className='left-padding7rem right-padding7rem my-md-5 mb-3'>
            <Card className='padding3xem' style={{ boxShadow:'9.899px 9.899px 30px 0 rgba(0,0,0,.1)', border:'none' }}>
                <Row>
                    <Col md='11' xl='6' >
                        <img src='blogs/Screen-time-Valley-Pediatrics-Blog4-Featured-image.jpg' alt='Blogs Img' width='100%' style={{ boxShadow:'8px 10px 20px 0px rgba(0, 0, 0, 0.35)', borderRadius:'1rem' }} />
                    </Col>
                    <Col md='11' xl='6' className=''>
                    <div className='ps-md-2'>
                        <div className='mt-md-0 mt-3'>
                            <Calendar className='me-2 text-ss-black'/>
                            <text className='text-ss-black'>August 23, 2022</text>    
                        </div>
                        <div className='my-3 my-md-4'>
                        <h2 className='fs-ss-28 fw-600 lh-1-2'>
                           <a style={{ textDecoration:'none'}} href='/blog' className='text-black text-raleway fw-800 fs-ss-26-1'>Screen time: What are the issues surrounding too much screen time?  </a>
                        </h2>
                        </div>
                        <div className='py-2 py-md-0'>
                            <text className='fs-ss-16 text-poppins lh-1-8'>
                            These days, almost everyone always has some sort of screen around. There is significant evidence that spending too much time in front of a screen is bad for both kids and adults. It’s likely your family can benefit from reducing screen exposure. Keep reading to find out what happens to your body and mind when […]                           
                            </text>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Card>
        </div>

        <div className='left-padding7rem right-padding7rem my-md-5 mb-3'>
            <Card className='padding3xem' style={{ boxShadow:'9.899px 9.899px 30px 0 rgba(0,0,0,.1)', border:'none' }}>
                <Row>
                    <Col md='11' xl='6' >
                        <img src='blogs/Keeping-Kids-Safe-in-the-Heat-Valley-Pediatrics-Blog3-Featured-image.jpg' alt='Blogs Img' width='100%' style={{ boxShadow:'8px 10px 20px 0px rgba(0, 0, 0, 0.35)', borderRadius:'1rem' }} />
                    </Col>
                     <Col md='11' xl='6' className=''>
                    <div >
                        <div className='mt-md-0 mt-3'>
                            <Calendar className='me-2 text-ss-black'/>
                            <text className='text-ss-black'>July 14, 2022</text>    
                        </div>
                        <div className='my-4'>
                        <h2 className='fs-ss-28 fw-600 lh-1-2'>
                           <a style={{ textDecoration:'none'}} href='/blog' className='text-black text-raleway fw-800 fs-ss-26-1'>Keeping Kids Safe in the Heat </a>
                        </h2>
                        </div>
                        <div className='py-3 py-md-0'>
                            <text className='fs-ss-16 text-poppins lh-1-8'>
                            Summer means fun times and sunshine! But that sunshine also means higher temperatures. Scorching weather lasting longer than usual has become a health concern for many parents and health care providers. With these high temperatures, parents need to take special precautions to keep their children safe. While warm temperatures help facilitate lots of fun summer […]                         
                            </text>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Card>
        </div>

        <div className='left-padding7rem right-padding7rem my-md-5 mb-3'>
            <Card className='padding3xem' style={{ boxShadow:'9.899px 9.899px 30px 0 rgba(0,0,0,.1)', border:'none' }}>
                <Row>
                    <Col md='11' xl='6' >
                        <img src='blogs/Covid-in-Children-Valley-Pediatrics-Blog2-Featured-image-1024x683.jpg' alt='Blogs Img' width='100%' style={{ boxShadow:'8px 10px 20px 0px rgba(0, 0, 0, 0.35)', borderRadius:'1rem' }} />
                    </Col>
                     <Col md='11' xl='6' className=''>
                    <div className='ps-md-2'>
                        <div className='mt-md-2 mt-4'>
                            <Calendar className='me-2 text-ss-black'/>
                            <text className='text-ss-black'>June 22, 2022</text>    
                        </div>
                        <div className='my-3'>
                        <h2 className='fs-ss-28 fw-600'>
                           <a style={{ textDecoration:'none'}} href='/blog' className='text-black text-raleway fw-800 fs-ss-26-1'>COVID In Children </a>
                        </h2>
                        </div>
                        <div className='py-3 py-md-0'>
                            <text className='fs-ss-16 text-poppins lh-1-8'>
                            Early in 2020, COVID turned our lives upside down in all aspects of life. Millions of people have lost their lives, and thousands of people who contracted the virus are still recovering. While we are all anxious to get back to our “normal” lives, COVID is still lingering and impacting our lives in several ways. […]                       
                            </text>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Card>
        </div>
        
        <div className='left-padding7rem right-padding7rem my-md-5 mb-3'>
            <Card className='padding3xem' style={{ boxShadow:'9.899px 9.899px 30px 0 rgba(0,0,0,.1)', border:'none' }}>
                <Row>
                    <Col md='11' xl='6' >
                        <img src='blogs/Fever-in-Children-Valley-Pediatrics-Blog1-Featured-image-1024x682.jpg' alt='Blogs Img' width='100%' style={{ boxShadow:'8px 10px 20px 0px rgba(0, 0, 0, 0.35)', borderRadius:'1rem' }} />
                    </Col>
                     <Col md='11' xl='6' className=''>
                    <div className='ps-md-2'>
                        <div className='mt-md-4 mt-2'>
                            <Calendar className='me-2 text-ss-black'/>
                            <text className='text-ss-black'> June 7, 2022</text>    
                        </div>
                        <div className='my-3'>
                        <h2 className='fs-ss-28 fw-600 lh-1-2'>
                           <a style={{ textDecoration:'none'}} href='/blog' className='text-black text-raleway fw-900 fs-ss-26-1'> Fever in Children: When does it become a concern?</a>
                        </h2>
                        </div>
                        <div className='py-3 py-md-0'>
                            <text className='fs-ss-16 text-poppins lh-1-8'>
                            Fever is one of the most concerning medical symptoms that parents have to experience with their children. Most often, fever resolves on its own, but sometimes it requires treatment by professionals. It has been observed that many new parents consider even a mild temperature rise in their kid’s body as fever. Slight variations of normal […]              
                            </text>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Card>
        </div> */}
    </div>
  )
}

export default BlogsTopics