import React from 'react'
import { Col, Row } from 'reactstrap'


const HomeCaringHands = () => {
  return (
    <div id='website-responsive' style={{ backgroundColor:'#f1efef' }}>
        <div className=''>
        <div className='py-5'>
            <div className='pb-4'>
                <h3 className='fs-ss-40 text-raleway fw-700'>
                <center>
                    Meet the Hands that Care
                </center>
                </h3>
            </div>
            <Row className='align-items-start justify-content-center'>
                <Col md='3' xl='2' className='p-md-3 me-md-5 pb-5' >
                <div style={{ marginBottom:'20px'}}>
                    <center>
                    <img src='home/Amber-Lewis-v1.png' alt='Amber Lewis picture' width='' className='imgs-caring-hands' />
                    </center>
                </div>
                <div>
                    <text>
                        <center className='fs-ss-22 text-ss-primary fw-500 text-roboto'>
                            Amber Lewis
                        </center>
                    </text>
                </div>
                <div>
                    <text>
                        <center className='fs-ss-22 text-ss-primary fw-500 text-roboto'>
                            CRNP, CPNP-PC
                        </center>
                    </text>
                </div>
                </Col>
                
                <Col md='3' xl='2' className='p-md-3 me-md-5  pb-5'>
                <div style={{ marginBottom:'20px'}}>
                    <center>
                    <img src='home/Bethany-Nemati-v1.png' alt='Caring Hands' width='' className='imgs-caring-hands' />
                    </center>
                </div>
                <div>
                    <text>
                        <center className='fs-ss-22 text-ss-primary fw-500 text-roboto'>
                            Bethany Nemati
                        </center>
                    </text> <br/>
                </div>
                <div>
                    <text >
                        <center style={{ color:''}} className='fs-ss-22 text-ss-primary fw-500 text-roboto'>
                            
                        </center>
                    </text>
                </div>
                </Col>

                <Col md='3' xl='2' className='p-md-3  '>
                <div style={{ marginBottom:'20px'}}>
                    <center>
                    <img src='home/Keaton-Aldrup-v1.png' alt='Caring Hands' width='' className='imgs-caring-hands' />
                    </center>
                </div>
                <div>
                    <text>
                        <center className='fs-ss-22 text-ss-primary fw-500 text-roboto'>
                            Keaton Aldrup
                        </center>
                    </text>
                </div>
                <div>
                    <text>
                        <center className='fs-ss-22 text-ss-primary fw-500 text-roboto'>
                            MHNP
                        </center>
                    </text>
                </div>
                </Col>
            </Row>
            </div>
        </div>
    </div>
  )
}

export default HomeCaringHands