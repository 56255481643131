import React from 'react'
import { Col, Row } from 'reactstrap'

const AdsPageProviders = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem-res right-padding7rem-res p-top-bottom2rem'>
            <div className='pb-md-5 pb-4'>
                <text className='fs-ss-36 text-roboto-con fw-900 text-ss-black' style={{ letterSpacing:'1px'}}>
                    <center>
                        OUR PROVIDERS
                        <hr className='hr-style' style={{ width:'130px', marginTop:'0px', marginBottom:'4px'}}/>
                    </center>
                </text>
            </div>

            <div className='flex-'>
                <Row className='flex-center' style={{ gap:'4rem'}}>
                    <Col md='10' xl='3' className='bg-img' style={{ backgroundImage:'linear-gradient(180deg,#fff 30%,#f4f4f4 30%)', borderRadius:'25px 25px 0 0', boxShadow:'3px 4px 10px 0 rgba(0,0,0,.3)', borderStyle:'solid', borderWidth:'6px', borderColor:'silver', padding:'3rem 1rem' }}>
                        <div className='' style={{  }}>
                            <center>
                                <img src='/home/Amber-Lewis-v1.png' alt='providers' width='170px' height='auto' />
                            </center>
                        </div>

                        <div className='pt-3'>
                            <text className='fs-ss-22-2 text-roboto text-ss-primary fw-600 lh-1'>
                                <center>
                                    Amber Lewis
                                </center>
                            </text>
                        </div>

                        <div className='pb-2'>
                            <text className='fs-ss-22-2 text-roboto text-ss-primary fw-600'>
                                <center>
                                    CRNP, CPNP-PC
                                </center>
                            </text>
                        </div>

                        <div className=''>
                            <text className='fs-ss-12 text-roboto fw-500 ' style={{ lineHeight:2.5}}>
                                <center>
                                    Amber has been a skilled and caring pediatric provider for over five years and now lends her talents as a devoted nurse to our clinic to ensure that the children of Guntersville are properly cared for in all things medical. She devotes herself to ensuring the little ones feel better.
                                </center>
                            </text>
                        </div>
                    </Col>

                    <Col md='10' xl='3' className='bg-img' style={{ backgroundImage:'linear-gradient(180deg,#fff 30%,#f4f4f4 30%)', borderRadius:'25px 25px 0 0', boxShadow:'3px 4px 10px 0 rgba(0,0,0,.3)', borderStyle:'solid', borderWidth:'6px', borderColor:'silver', padding:'2rem 1rem' }}>
                        <div className='pb-3' style={{  }}>
                            <center>
                                <img src='/home/Bethany-Nemati-v1.png' alt='providers' width='170px' height='auto' />
                            </center>
                        </div>

                        <div className='pt- pb-4'>
                            <text className='fs-ss-22-2 text-roboto text-ss-primary fw-600'>
                                <center>
                                    Bethany Nemati
                                </center>
                            </text>
                        </div>

                        <div className=''>
                            <text className='text-'>
                                <center>
                                    
                                </center>
                            </text>
                        </div>

                        <div className='pt-md-2'>
                            <text className='fs-ss-12 text-roboto fw-500 ' style={{ lineHeight:2.5}}>
                                <center>
                                    Bethany Nemati spent her childhood in Harvest and went to Huntsville for higher studies, where she received her degree in mathematics from the University of Alabama. But her passion for serving the community drove her to the University of the Alabama School of Medicine, and Prisma Health Pediatric Residency in Columbia, SC                                
                                </center>
                            </text>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default AdsPageProviders