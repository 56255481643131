import React from 'react'
import { Col, Row } from 'reactstrap'
import Icon1 from '../../assets/images/ads/Icon1.png'
import Icon2 from '../../assets/images/ads/Icon2.png'
import Icon3 from '../../assets/images/ads/Icon3.png'


const AdsPageValleyPediatrics = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem right-padding7rem py-5'>
            <div className='pb-1'>
                <text className='fs-ss-40-1 text-roboto-con text-ss-black fw-800' style={{ letterSpacing:'0.9px'}}>
                    VALLEY PEDIATRICS
                    <hr className='hr-style' style={{ width:'100px', marginTop:'0px', marginBottom:'4px'}}/>
                </text>
            </div>

            <div className='pt-2'>
                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                    We offer reliable healthcare for your children at Valley Pediatrics with compassion. We get that it can be hard to find a good pediatric clinic in Guntersville for your child, which is why we are accepting new patients! We ensure that your child’s health is never compromised, even if the ailments come a little out of the blue. Our providers entertain same-day appointments to give your child the care they need as soon as possible.
                </text>
            </div>

            <div className='py-5 text-center'>
                <Row className=''>
                    <Col sm='12' md='4' className='pb-4'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon1} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Same Day Sick Visits</text>
                        </div>
                    </Col>

                    <Col sm='12' md='4' className='py-4 py-md-0'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon2} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Most Insurances Accepted</text>
                        </div>
                    </Col>

                    <Col sm='12' md='4' className='py-4 py-md-0'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon3} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Accepting New Patients</text>
                        </div>
                    </Col>
                </Row>
            </div>
            
        </div>
    </div>
  )
}

export default AdsPageValleyPediatrics