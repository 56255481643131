import React from 'react'
import { TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'

const PatientFormCallNow = () => {
  return (
    <div id='website-responsive'>
        <div className='py-md-5 py-4 ' style={{ borderTop:'1px solid black'}}>
            <div className='pb-2 pt-md-4 px-md-0 px-5'>
                <h2 className='fs-ss-32 text-raleway fw-700 lh-1'>
                    <center>
                        FOR MORE INFORMATION
                    </center>
                </h2>
            </div>

            <div className='pb-4'>
                <p className='fs-ss-26 text-raleway fw-600'>
                    <center>
                        Feel free to call us!
                    </center>
                </p>
            </div>

            <div className='pb-md-5 pb-4'>
            <center>
                <a target='_blank' rel='noreferrer' href='tel:+12562021111'>
                    <Button className='btn-ss-primary rounded30 btn-hover' style={{ padding:'6px 30px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)'}}>
                        <div className=''>
                            <TelephoneFill className='fs-ss-22 fw-600 text-roboto me-2 mb-1' />
                            <span className='fs-ss-22-1 fw-600 text-roboto'>(256) 202 1111</span>
                        </div>
                    </Button>
                </a>
            </center>
            </div>
        </div>
    </div>
  )
}

export default PatientFormCallNow