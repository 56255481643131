import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import VaccineScheduleMain from '../components/vaccine-schedule/VaccineScheduleMain'
import VaccineScheduleHealthyLife from '../components/vaccine-schedule/VaccineScheduleHealthyLife'
import VaccineScheduleYourChildrenNeed from '../components/vaccine-schedule/VaccineScheduleYourChildrenNeed'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const VaccineSchedulePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>

      <HelmetProvider>
          <Helmet>
          <title>Pediatric Vaccine Schedule and Information &ndash; Valley Pediatrics</title>
          <meta name="description" content="Never miss a vaccine shot for your child at the right time to give them the best chance at a healthy life. Visit Valley Pediatrics for the vaccine schedules." />
          <link rel="canonical" href="https://www.valley-pediatrics.com/vaccine-schedule-and-information/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Pediatric Vaccine Schedule and Information &ndash; Valley Pediatrics" />
          <meta property="og:description" content="Never miss a vaccine shot for your child at the right time to give them the best chance at a healthy life. Visit Valley Pediatrics for the vaccine schedules." />
          <meta property="og:url" content="https://www.valley-pediatrics.com/vaccine-schedule-and-information/" />
          <meta property="og:site_name" content="Valley Pediatrics" />
          <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
          <meta property="article:modified_time" content="2022-04-07T07:19:19+00:00" />
          <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="2 minutes" />
          </Helmet>
      </HelmetProvider>
        <TopHeader/>
        <MainHeader/>

        <VaccineScheduleMain/>
        <VaccineScheduleHealthyLife/>
        <VaccineScheduleYourChildrenNeed/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default VaccineSchedulePage