import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'

const InquiryForm = () =>{
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
  emailjs.sendForm('service_smtp','valley_pediatrics',e.target,'-Hu0MzJr-44dDQfrl').then(res=>{
    console.log(res)
    alert("Your form has been submitted")
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="Get" action="#">
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="first_name">First Name</label>
        <input type="text" className="form-control bg-light" id="first_name" name="first_name" placeholder="First Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="last_name">Last Name</label>
        <input type="text" className="form-control bg-light" id="last_name" name="last_name" placeholder="Last Name*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="email">Email Address</label>
        <input type="email" className="form-control bg-light" id="email" name="email" placeholder="Email Address*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="phone_number">Phone Number</label>
        <input type="number" className="form-control bg-light" id="phone_number" name="phone_number" placeholder="Phone Number*" required/>
      </div>
      <div className="form-group">
        <label className="invisible font-pri fw-600" for="message">message</label>
        <textarea className="col-pri form-control bg-light" id="message" name="message" rows="5" placeholder="Message*" required></textarea>
      </div>
      <div className="pt-3 text-center">
      <ReCAPTCHA
    sitekey="6LeXQA0pAAAAAG5aIKIUoE2ZYdhHheaeCh6cs1qT" 
    onChange={onChange}/>
 <button type='submit' className='btn-full-wid btn-ss-secondary mt-4'  style={{ width:'100%', backgroundColor:'', borderRadius:'0px', border:'none'}}>
                    <text className='fs-ss-16 text-roboto'>
                        Submit
                    </text>
                </button>
      </div>
    </form>
  
    </>
    )
}

export default InquiryForm;