import React from 'react'
import { FilePdfFill } from 'react-bootstrap-icons'
import { Col, Row } from 'reactstrap'
import PDF1 from '../../assets/images/pdf/BRIGHT FUTURES 3-5 DAYS OLD.pdf'
import PDF2 from '../../assets/images/pdf/BRIGHT FUTURES 1 MONTH.pdf'
import PDF3 from '../../assets/images/pdf/BRIGHT FUTURES 2 MONTHS.pdf'
import PDF4 from '../../assets/images/pdf/BRIGHT FUTURES 4 MONTHS.pdf'
import PDF5 from '../../assets/images/pdf/BRIGHT FUTURES 6 MONTHS.pdf'
import PDF6 from '../../assets/images/pdf/BRIGHT FUTURES 9 MONTHS.pdf'
import PDF7 from '../../assets/images/pdf/BRIGHT FUTURES 12 MONTHS.pdf'
import PDF8 from '../../assets/images/pdf/BRIGHT FUTURES 15 MONTHS.pdf'
import PDF9 from '../../assets/images/pdf/BRIGHT FUTURES 18 MONTHS.pdf'
import PDF10 from '../../assets/images/pdf/BRIGHT FUTURES 2 YEARS.pdf'
import PDF11 from '../../assets/images/pdf/BRIGHT FUTURES 3 YEARS.pdf'
import PDF12 from '../../assets/images/pdf/BRIGHT FUTURES 4 YEARS.pdf'
import PDF13 from '../../assets/images/pdf/BRIGHT FUTURES 5-6 YEARS.pdf'
import PDF14 from '../../assets/images/pdf/BRIGHT FUTURES 7-8 YEARS.pdf'
import PDF15 from '../../assets/images/pdf/BRIGHT FUTURES 9-10 YEARS.pdf'
import PDF16 from '../../assets/images/pdf/BRIGHT FUTURES 11-14 YEARS.pdf'
import PDF17 from '../../assets/images/pdf/BRIGHT FUTURES 15-17 YEARS.pdf'

const WellVisitPdf = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 p-top-bottom2rem1'>
            <Row className='py-md-2'>
                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF1} >BRIGHT FUTURES 3-5 DAYS OLD</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF2}>BRIGHT FUTURES 1 MONTH</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF3}>BRIGHT FUTURES 2 MONTHS</a></text>
                    </div>
                </Col>
            </Row>

            <Row className='py-md-2'>
                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF4}>BRIGHT FUTURES 4 MONTHS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF5}>BRIGHT FUTURES 6 MONTHS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF6}>BRIGHT FUTURES 9 MONTHS</a></text>
                    </div>
                </Col>
            </Row>

            <Row className='py-md-2'>
                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF7}>BRIGHT FUTURES 12 MONTHS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF8}>BRIGHT FUTURES 15 MONTHS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF9}>BRIGHT FUTURES 18 MONTHS</a></text>
                    </div>
                </Col>
            </Row>

            <Row className='py-md-2'>
                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF10}>BRIGHT FUTURES 2 YEARS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF11}>BRIGHT FUTURES 3 YEARS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF12}>BRIGHT FUTURES 4 YEARS</a></text>
                    </div>
                </Col>
            </Row>

            <Row className='py-md-2'>
                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF13}>BRIGHT FUTURES 5-6 YEARS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF14}>BRIGHT FUTURES 7-8 YEARS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF15}>BRIGHT FUTURES 9-10 YEARS</a></text>
                    </div>
                </Col>
            </Row>

            <Row className='py-md-2'>
                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF16}>BRIGHT FUTURES 11-14 YEARS</a></text>
                    </div>
                </Col>

                <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-20 text-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF17}>BRIGHT FUTURES 15-17 YEARS</a></text>
                    </div>
                </Col>

                {/* <Col xs='12' md='4' className='pb-3 pb-md-1'>
                    <div className=''>
                        <FilePdfFill className='me-2 fs-ss-22 te0t-ss-primary'/>
                        <text className='fs-ss-16-3 text-roboto fw-400' style={{ cursor:'pointer'}}><a className='text-black' style={{ textDecoration:'none'}} target='_blank' rel='noreferrer' href={PDF1}>BRIGHT FUTURES 2 YEARS</a></text>
                    </div>
                </Col> */}
            </Row>
        </div>
    </div>
  )
}

export default WellVisitPdf