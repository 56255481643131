import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/arab/Pediatric-Clinic-near-Arab-pic2.webp'

const ArabWellVisit = () => {
  return (
    <div id='website-responsive' style={{ backgroundColor:'#f4f4f4'}}>
        <div className='left-padding7rem1 right-padding6rem py-md-5 py-3'>
            <div className=''>
                <Row className='reverse-section'>
                    <Col md='5' className=''>
                        <div className='pt-md-0 pt-4 pb-md-0 pb-3'>
                            <img src={Img1} alt='Well Visit' height='auto' width='100%' />
                        </div>
                    </Col>

                    <Col md='7' className='ps-md-5'>
                        <div className=''>
                            <div className='pb-2'>
                                <text className='fs-ss-32 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                    Valley Well-Visits And Immunization
                                </text>
                            </div>

                            <div className='pb-3'>
                                <text className='fs-ss-22-2 text-raleway fw-700 lh-1-2' >
                                    Let's Catch Up on our child's health
                                </text>
                            </div>

                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                                    You can unquestionably keep your child healthy. And making sure your child sees their doctor for well-child checkups and prescribed vaccines is one of the finest things you can do to protect your child and community from dangerous diseases that are easily spread.
                                </text>
                            </div>

                            <div className='pt-1'>
                                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                                    We will monitor your child’s growth and development, do age-appropriate tests and screenings, go through developmental milestones, and provide necessary vaccines at your well-visit because children grow rapidly and develop a great deal.
                                </text>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='py-md-5 py-4'>
                <div className='pb-3'>
                    <text className='fs-ss-28-2 text-raleway fw-700 lh-1-2 text-ss-black'>
                        We Look After Your Child
                    </text>
                </div>

                <div className='pb-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Preventive healthcare is one of the most important aspects of a well-child examination. This means that these routine visits are essential for having health-related conversations with your pediatrician, even if your child is well. The doctor will go over important topics like growth, nutrition, safety, sleep, and other issues you should be aware of as your child gets older at this visit.
                    </text>
                </div>

                <div className='pt-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Your child’s weight, height, and head circumference will also be recorded on a growth chart to keep with their medical records. Doctors will eventually be able to determine your child’s growth trend using all this information.
                    </text>
                </div>
            </div>

            <div className='py-m-5 py4'>
                <div className='pb-3'>
                    <text className='fs-ss-28-2 text-raleway fw-700 lh-1-2 text-ss-black'>
                        When should you schedule a well-visit?
                    </text>
                </div>

                <div className='pb-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Pediatric exams on a regular basis are the cornerstone of a healthy lifestyle for your child. To help you and your child lead a healthy life, the American Academy of Pediatrics recommends the pediatric well visit schedule below for your child’s well-child checkups: After you bring your newborn home from the hospital, you’ll probably make your first visit a few days later.
                    </text>
                </div>

                <div className='pt-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Following that, you can anticipate six appointments by the time the child becomes one, two more checkups before they turn two, and then at least one checkup every year until they are 21. Naturally, this strategy might be altered depending on your child’s health or if your pediatrician discovers any developmental problems.
                    </text>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ArabWellVisit