import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/ads/Pediatric-Clinic-near-Albertville-pic2.webp'

const AlbertvilleWellVisits = () => {
  return (
    <div id='website-responsive' style={{ backgroundColor:'#f4f4f4'}}>
        <div className='left-padding7rem1 right-padding6rem py-md-5 py-3'>
            <div className=''>
                <Row className='reverse-section'>
                    <Col md='5' className=''>
                        <div className='pt-md-0 pt-4 pb-md-0 pb-3'>
                            <img src={Img1} alt='Well Visit' height='auto' width='100%' />
                        </div>
                    </Col>

                    <Col md='7' className='ps-md-4'>
                        <div className=''>
                            <div className='pb-2'>
                                <text className='fs-ss-32 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                    Well Visits & Immunizations
                                </text>
                            </div>

                            <div className='pb-3'>
                                <text className='fs-ss-22-2 text-raleway fw-700 lh-1-2' >
                                    Let's assess your child's health
                                </text>
                            </div>

                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                                    Maintaining your child’s health is absolutely possible. One of the best things you can do to safeguard your child and the community against serious diseases that are quickly spread is to ensure that your child visits the doctor for well-child exams and any recommended vaccinations.
                                </text>
                            </div>

                            <div className='pt-1'>
                                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                                    At your well-visit, we will keep an eye on your child’s growth and development, do tests and screenings that are age-appropriate, talk through developmental milestones, and provide any necessary vaccinations to assess the rapid growth and development of your child.
                                </text>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='py-md-5 py-4'>
                <div className='pb-3'>
                    <text className='fs-ss-28-2 text-raleway fw-700 lh-1-2 text-ss-black'>
                        We Take Care of Your Child
                    </text>
                </div>

                <div className='pb-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        One of the most crucial components of a well-child exam is preventive healthcare. This means that even if your child is well, these routine visits are necessary for having health-related conversations with your pediatrician. During this visit, the doctor will go over crucial subjects including development, nutrition, safety, sleep, and other concerns you should be aware of as your child gets older.
                    </text>
                </div>

                <div className='pt-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        A growth chart will also be used to record your child’s weight, height, and head circumference for their medical records. With the use of all this data, doctors will eventually be able to identify your child’s growth pattern.
                    </text>
                </div>
            </div>

            <div className='py-m-5 py4'>
                <div className='pb-3'>
                    <text className='fs-ss-28-2 text-raleway fw-700 lh-1-2 text-ss-black'>
                        When should a well-visit be scheduled?
                    </text>
                </div>

                <div className='pb-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Regular pediatric examinations are the foundation of a healthy lifestyle for your child. The American Academy of Pediatrics suggests the pediatric well visit schedule below for your child’s well-child checks in order to assist you and your child live a healthy life: You’ll probably pay your first visit a few days after you bring your newborn home from the hospital.
                    </text>
                </div>

                <div className='pt-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Then, by the time the child becomes one, you may anticipate six appointments, two more checkups before they age two, and then at least one checkup a year until they are 21. Naturally, this plan might need to be modified if your pediatrician notices any developmental issues or if your child’s health shifts.
                    </text>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AlbertvilleWellVisits