import React from 'react'
import { Col, Row } from 'reactstrap'
import Image1 from '../../../assets/images/Pediatric-Clinic-Boaz-pic-v1.webp'

const BoazWellVisit = () => {
  return (
    <div id='website-responsive' style={{ backgroundColor:'#f4f4f4'}}>
        <div className='left-padding7rem1 right-padding6rem py-md-5 py-3'>
            <div className=''>
                <Row className='reverse-section gy-5'>
                    <Col md='11' xl='5' className=''>
                        <div className='pt-md-0 pt-4 pb-md-0 pb-3'>
                            <img src={Image1} alt='Well Visit' height='auto' width='100%' />
                        </div>
                    </Col>

                    <Col md='11' xl='7' className='ps-md-5'>
                        <div className=''>
                            <div className='pb-2'>
                                <text className='fs-ss-32 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                    Well Visits & Immunizations
                                </text>
                            </div>

                            <div className='pb-3'>
                                <text className='fs-ss-22-2 text-raleway fw-700 lh-1-2' >
                                    Let's Catch Up on the Wellness of Your Child
                                </text>
                            </div>

                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                                    Without a doubt, you can maintain your child’s health. And one of the best things you can do to protect your child and community from deadly diseases that are easily spread is to ensure that your child visits their doctor for well-child visits and recommended immunizations.
                                </text>
                            </div>

                            <div className='pt-1'>
                                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                                    Because children experience rapid growth and change frequently, we will monitor your child’s growth and development, carry out age-appropriate exams and screenings, go through developmental milestones, and administer required vaccines at your well-visit.
                                </text>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className='py-md-5 py-4'>
                <div className='pb-3'>
                    <text className='fs-ss-28-2 text-raleway fw-700 lh-1-2 text-ss-black'>
                        We Watch Over Your Child
                    </text>
                </div>

                <div className='pb-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        One of the most significant components of a well-child checkup is preventive healthcare. This means that even if your child is well, these regular checkups are still crucial for having health-related conversations with your family pediatrician. During this visit, the doctor will cover the growth, nutrition, safety, sleep, and other critical subjects you will need to be aware of as your child ages.
                    </text>
                </div>

                <div className='pt-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        In addition, a growth chart stored with your child’s medical records will include measurements of your child’s weight, height, and head circumference. All this data will eventually help doctors identify your child’s growth pattern.
                    </text>
                </div>
            </div>

            <div className='py-m-5 py4'>
                <div className='pb-3'>
                    <text className='fs-ss-28-2 text-raleway fw-700 lh-1-2 text-ss-black'>
                        When to go for a well-visit?
                    </text>
                </div>

                <div className='pb-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        The foundation of a healthy lifestyle for your child is routine pediatric examinations. The American Academy of Pediatrics suggests the following pediatric well visit schedule for your kid’s well-child checkups to guide you and your child toward a healthy life: Your first visit will most likely be a few days after you bring your newborn home from the hospital.
                    </text>
                </div>

                <div className='pt-1'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        After that, you should expect six visits by the time the child is one, two more checkups before the child turns two, and then at least one checkup a year until the child is 21. Of course, this plan could change based on your child’s health or if your pediatrician notices any developmental issues.
                    </text>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BoazWellVisit