import React from 'react'

const PatientFormEnsuring = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem right-padding7rem py-md-5 py-4'>
            <div className=''>
                <h2 className='fs-ss-36 fw-800 text-ss-primary text-raleway lh-1' style={{ letterSpacing:'0.9px'}}>
                    Ensuring Your Convenience
                </h2>
            </div>

            <div className='pb-4'>
                <h3 className='fs-ss-32 text-raleway fw-700 lh-1' style={{ letterSpacing:'0.9px' }}>
                    In Your Child’s Healthcare
                </h3>
            </div>

            <div className=''>
                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                    We know there is no extent you would not go to guarantee that your child is in prime physical health. But it can be exhausting. So, we have made it a mission to ensure that we can make the struggle a little easier for you. We’ve compiled all possible forms you may need as a parent for registering your child as a new patient. We have all your Valley Pediatrics patient forms in one place for your convenience because accessibility and quality care are our priorities. For any further information, do not hesitate to contact our providers at the office!
                </p>
            </div>
        </div>
    </div>
  )
}

export default PatientFormEnsuring