import React from 'react'

const InsAndFinEnsuringHealth = () => {
  return (
    <div id='website-responsive'>
    <div className='left-padding7rem right-padding7rem1 p-top-bottom2rem2'>
        <div className='pb-md-4 pb-3'>
            <h3 className='fs-ss-32 text-raleway text-ss-primary fw-700 lh-1'>
                Ensuring Health For Your Little Ones
            </h3>
        </div>

        <div className=''>
            <span className='fs-ss-16-2 text-roboto lh-1-8'>
                At Valley Pediatrics, we pride ourselves on being accessible and compassionate, so we choose to accept most commercial insurance plans for your ease, including Medicaid, Blue Cross, Tricare, etc. In recent times the number of healthcare plan options has started increasing substantially. We endeavor to ensure that we are in contact with your insurance provider, working to make sure that the available services provided are covered by whoever represents you. However, regardless of how dedicated we are to your comfort, some things are just not under our control. Therefore, we cannot guarantee that your insurance company will cover all the services provided to your child at our clinic. Patients and their representative parties are responsible for any cost not covered by their healthcare plan. 
            </span>
        </div>
    </div>

    </div>
  )
}

export default InsAndFinEnsuringHealth