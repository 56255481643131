import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import SickVisitsMain from '../components/sick-visits/SickVisitsMain'
import SickVisitsRoadToWellness from '../components/sick-visits/SickVisitsRoadToWellness'
import SickVisitsAppointmets from '../components/sick-visits/SickVisitsAppointmets'
import SickVisitsCovidTesting from '../components/sick-visits/SickVisitsCovidTesting'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const SickVisitsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>

      <HelmetProvider>
          <Helmet>
          <title>Pediatric Sick Visits in Guntersville &ndash; Valley Pediatrics</title>
          <meta name="description" content="Ensure that your child has great healthcare by visiting Valley Pediatrics for the best pediatric sick visits Guntersville has to offer!" />
          <link rel="canonical" href="https://www.valley-pediatrics.com/sick-visits/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Pediatric Sick Visits in Guntersville &ndash; Valley Pediatrics" />
          <meta property="og:description" content="Ensure that your child has great healthcare by visiting Valley Pediatrics for the best pediatric sick visits Guntersville has to offer!" />
          <meta property="og:url" content="https://www.valley-pediatrics.com/sick-visits/" />
          <meta property="og:site_name" content="Valley Pediatrics" />
          <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
          <meta property="article:modified_time" content="2022-05-25T10:29:16+00:00" />
          <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="2 minutes" />
          </Helmet>
      </HelmetProvider>
        <TopHeader/>
        <MainHeader/>

        <SickVisitsMain/>
        <SickVisitsRoadToWellness/>
        <SickVisitsAppointmets/>
        <SickVisitsCovidTesting/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default SickVisitsPage