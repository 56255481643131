import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import DoseChartsMain from '../components/dose-charts/DoseChartsMain'
import DoseChartsTylenol from '../components/dose-charts/DoseChartsTylenol'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const DoseChartsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>
        <HelmetProvider>
            <Helmet>
            <title>Pediatric Dose Charts &ndash; Valley Pediatrics</title>
            <meta name="description" content="Ensure that your children are getting the right dosages for their age and physicality, view the dosage charts for Tylenol and Motrin at Valley Pediatrics." />
            <link rel="canonical" href="https://www.valley-pediatrics.com/dose-charts/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Pediatric Dose Charts &ndash; Valley Pediatrics" />
            <meta property="og:description" content="Ensure that your children are getting the right dosages for their age and physicality, view the dosage charts for Tylenol and Motrin at Valley Pediatrics." />
            <meta property="og:url" content="https://www.valley-pediatrics.com/dose-charts/" />
            <meta property="og:site_name" content="Valley Pediatrics" />
            <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
            <meta property="article:modified_time" content="2022-04-07T07:17:56+00:00" />
            <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:type" content="image/png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="2 minutes" />
            </Helmet>
        </HelmetProvider>

        <TopHeader/>
        <MainHeader/>

        <DoseChartsMain/>
        <DoseChartsTylenol/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default DoseChartsPage