import React from 'react'

const AboutEncourageGoodHealth = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem right-padding7rem py-md-5 py-3'>
            <div>
                <h2 className='fs-ss-36 text-raleway fw-800 text-ss-primary'>
                    At Valley Pediatrics
                </h2>
            </div>

            <div>
                <text className='fs-ss-20 text-raleway fw-700'>
                    We aspire to encourage good health and strength in our little patients.
                </text>
            </div>

            <div className='pt-4'>
                <text className='fs-ss-16 text-roboto'>
                    Our devoted team of healthcare providers is willing to go above and beyond to ensure that your child feels as  cared for as possible during their appointments. We acknowledge that each child is unique and that their medical, social, and developmental needs are not the same as adults or even other children. We ensure that we provide customized care to every patient with great dedication towards their comfort. Our compassionate providers are specifically trained to fulfill the diverse needs of the little ones who step foot in Valley Pediatric Clinic.  
                </text>
            </div>

            <div className='pt-3'>
                <text className='fs-ss-16 text-roboto'>
                     Whether your child is well, sick, or suffering from chronic or complex health concerns, we give our all to make their time with us as pleasant as possible. We understand how harrowing it is to see your child in pain; that’s why our staff’s compassion and consideration extend towards you. We keep you notified of all procedural options at every step of the process to ensure that you can make the best-informed decisions for your child’s care. Attentive healthcare for you and your family is our priority! 
                </text>
            </div>
        </div>
    </div>
  )
}

export default AboutEncourageGoodHealth