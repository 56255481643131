import React from 'react'

const InsAndFinPrice = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img left-padding7rem1 right-padding7rem1 p-top-bottom2rem2' style={{ backgroundImage:'url("insurance-financial/Insurance-Middle-Section-bg-webp.webp")', height:'100%', width:'100%'}}>
            <div className='left-padding3rem right-padding3rem pb-md-5 pb-4 pt-4'>
                <text className='fs-ss-29 text-roboto fw-500 lh-1-4 text-white'>
                    <center>
                        We believe that healthcare should be available for every child who needs it. So, we are honored to offer a low-cost fee schedule for visits of uninsured children.
                    </center>
                </text>
            </div>

            <center>
            <div className='bg-img pt-5 pb-5 bgw' style={{ backgroundImage:'url(/insurance-financial/Layer2.png)', height:'100%', width:'' }}>
                <div className=''>
                    <text className='fs-ss-36-2 text-montserrat fw-800 text-ss-primary'>
                        <center>
                            STARTING AT JUST $30
                        </center>
                    </text>
                </div>
            </div>
            </center>
        </div>
    </div>
  )
}

export default InsAndFinPrice