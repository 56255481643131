import React, { useState } from 'react'
import Faqs from '../../components/home/Faqs'

const HomeFaq = () => {

  return (
    <div id='website-responsive' style={{ backgroundColor:'#f1efef' }}>

<div className='left-padding7rem1 right-padding7rem1 pt-5' style={{ marginTop:'', marginLeft:'' }}>
            <h3 className='fs-ss-30-1 text-ss-primary fw-800 text-raleway' style={{ letterSpacing:'0.9px'   }}>
                Frequently Asked Questions
            </h3>
        </div>

         <section className=''>

<div className='left-padding7rem1 right-padding7rem1'>

    <div className='row mt-' id="faq-home">
        <div className='col-md-12 pb-md-5 pt-md-3 pb-4' style={{borderRadius:"", border:''}}>
            <Faqs/>
                </div>
                </div>
                </div>
                </section>
    </div>
  )
}

export default HomeFaq