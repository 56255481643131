import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import Img1 from '../../assets/images/patientforms/VALLEY PEDIATRICS RECORDS REQUEST FORM.pdf'
import Img2 from '../../assets/images/patientforms/VALLEY PEDIATRICS NEW PATIENT FORMS (1).pdf'
import Img3 from '../../assets/images/patientforms/ALABAMA SCHOOL MEDICATION ADMINISTRATION FORM.pdf'
import Img4 from '../../assets/images/patientforms/ALABAMA AHSAA SPORTS PHYSICAL FORM.pdf'
import Img5 from '../../assets/images/patientforms/Vanderbilt Assessment Parent Form.pdf'
import Img6 from '../../assets/images/patientforms/Vanderbilt Assessment Teacher Form.pdf'

const PatientFormPdfs = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img py-md-5 py-5' style={{ backgroundImage:'url("/patient-form/Patients-Form-Section-bg-v1-1.png")', height:'100%', width:'100%'}}>
            <div className='pb-5'>
                <text className='fs-ss-30-1 fw-800 text-raleway' style={{ letterSpacing:'0.9px', fontStyle:'italic' }}>
                    <center>New Patient's Forms</center>
                </text>
            </div>

            <div className='px-xl-5 mx-xl-5 px-3  pb-md-0 pb-4'>
            <center>
                <Row className='justify-content-center'>
                    <Col md='12' lg='6' className='pb-5'>
                    <a target='_blank' rel='noreferrer' href={Img1}>
                        <Button className='btn-ss-primary lh-1 rounded6' style={{ width:'90%', boxShadow:'1px 3px 8px 0 rgba(0,0,0,.4)', padding:'15px 25px'}}>
                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto fw-500'>
                                    Valley Pediatrics Release Records Request Form
                                </text>
                            </div>
                        </Button>
                    </a>   
                    </Col>

                    <Col md='12' lg='6' className='pt-2 pt-md-0'>
                    <a target='_blank' rel='noreferrer' href={Img2} >
                        <Button className='btn-ss-primary lh-1 rounded6' style={{ width:'90%', boxShadow:'1px 3px 8px 0 rgba(0,0,0,.4)', padding:'15px 25px'}}>
                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto fw-500'>
                                    Valley Pediatrics New Patient Forms
                                </text>
                            </div>
                        </Button>
                    </a>
                    </Col>
                </Row>
                </center>
            </div>

            <div className='py-md-5 pt-md-4 pt-5  pb-5'>
                <text className='fs-ss-30-1 fw-800 text-raleway' style={{ letterSpacing:'0.9px', fontStyle:'italic' }}>
                    <center>Other Forms</center>
                </text>
            </div>

            <div className='px-xl-5 mx-xl-5 px-3 '>
            <center>
                <Row className='pb-5 pb-md-0 justify-content-center'>
                    <Col md='12' lg='6' className='pb-5'>
                    <a target='_blank' rel='noreferrer' href={Img3} >
                        <Button className='btn-ss-primary lh-1 rounded6' style={{ width:'90%', boxShadow:'1px 3px 8px 0 rgba(0,0,0,.4)', padding:'15px 25px'}}>
                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto fw-500'>
                                    Alabama School Medication Administration Form
                                </text>
                            </div>
                        </Button>
                    </a>
                    </Col>

                    <Col md='12' lg='6' className=''>
                    <a target='_blank' rel='noreferrer' href={Img4} >
                        <Button className='btn-ss-primary lh-1 rounded6' style={{ width:'90%', boxShadow:'1px 3px 8px 0 rgba(0,0,0,.4)', padding:'15px 25px'}}>
                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto fw-500'>
                                    Alabama AHSAA Sports Physical Form
                                </text>
                            </div>
                        </Button>
                        </a>
                    </Col>
                </Row>

                <Row className='pb-md-4 pt-md-5 pt-xl-0 justify-content-center'>
                    <Col md='12' lg='6' className='pb-5'>
                    <a target='_blank' rel='noreferrer' href={Img5} >
                        <Button className='btn-ss-primary lh-1 rounded6' style={{ width:'90%', boxShadow:'1px 3px 8px 0 rgba(0,0,0,.4)', padding:'15px 25px'}}>
                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto fw-500'>
                                    Vanderbilt Assessment Parent Form
                                </text>
                            </div>
                        </Button>
                        </a>
                    </Col>

                    <Col md='12' lg='6' className=''>
                    <a target='_blank' rel='noreferrer' href={Img6} >
                        <Button className='btn-ss-primary lh-1 rounded6' style={{ width:'90%', boxShadow:'1px 3px 8px 0 rgba(0,0,0,.4)', padding:'15px 25px'}}>
                            <div className=''>
                                <text className='fs-ss-16-2 text-roboto fw-500'>
                                    Vanderbilt Assessment Teacher Form
                                </text>
                            </div>
                        </Button>
                        </a>
                    </Col>
                </Row>
                </center>
            </div>
        </div>

    </div>
  )
}

export default PatientFormPdfs