import React from 'react'
import TopHeader from '../../components/layout/TopHeader'
import MainHeader from '../../components/layout/MainHeader'
import ArabMain from '../../components/city-page/arab-city-page/ArabMain'
import Footer from '../../components/layout/Footer'
import CopyRights from '../../components/layout/CopyRights'
import ArabChildCare from '../../components/city-page/arab-city-page/ArabChildCare'
import ArabGateway from '../../components/city-page/arab-city-page/ArabGateway'
import ArabSickVisit from '../../components/city-page/arab-city-page/ArabSickVisit'
import ArabWellVisit from '../../components/city-page/arab-city-page/ArabWellVisit'
import ArabValleyPsychiatry from '../../components/city-page/arab-city-page/ArabValleyPsychiatry'
import ArabPediatricHealthcare from '../../components/city-page/arab-city-page/ArabPediatricHealthcare'
import ArabExceptionalCare from '../../components/city-page/arab-city-page/ArabExceptionalCare'
import ArabLocation from '../../components/city-page/arab-city-page/ArabLocation'

const ArabCityPage = () => {
  return (
    <div>
        <TopHeader/>
        <MainHeader/>

        <ArabMain/>
        <ArabChildCare/>
        <ArabGateway/>
        <ArabSickVisit/>
        <ArabWellVisit/>
        <ArabValleyPsychiatry/>
        <ArabPediatricHealthcare/>
        <ArabExceptionalCare/>
        <ArabLocation/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default ArabCityPage