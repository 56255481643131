import React from 'react'
import TopHeader from '../../components/layout/TopHeader'
import MainHeader from '../../components/layout/MainHeader'
import Footer from '../../components/layout/Footer'
import CopyRights from '../../components/layout/CopyRights'
import AlbertvilleMain from '../../components/city-page/albertville-city-page/AlbertvilleMain'
import AlbertvilleChildCare from '../../components/city-page/albertville-city-page/AlbertvilleChildCare'
import AlbertvilleGateway from '../../components/city-page/albertville-city-page/AlbertvilleGateway'
import AlbertvilleSickVisit from '../../components/city-page/albertville-city-page/AlbertvilleSickVisit'
import AlbertvilleWellVisits from '../../components/city-page/albertville-city-page/AlbertvilleWellVisits'
import AlbertvilleValleyPsychiatry from '../../components/city-page/albertville-city-page/AlbertvilleValleyPsychiatry'
import AlbertvillePediatrichealthcare from '../../components/city-page/albertville-city-page/AlbertvillePediatrichealthcare'
import AlbertvilleExceptionalCare from '../../components/city-page/albertville-city-page/AlbertvilleExceptionalCare'
import AlbertvilleLocation from '../../components/city-page/albertville-city-page/AlbertvilleLocation'

const AlbertvilleCityPage = () => {
  return (
    <div>
        <TopHeader/>
        <MainHeader/>
        <AlbertvilleMain/>
        <AlbertvilleChildCare/>
        <AlbertvilleGateway/>
        <AlbertvilleSickVisit/>
        <AlbertvilleWellVisits/>
        <AlbertvilleValleyPsychiatry/>
        <AlbertvillePediatrichealthcare/>
        <AlbertvilleExceptionalCare/>
        <AlbertvilleLocation/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default AlbertvilleCityPage