import React from 'react'
import { GeoAltFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'

const AdsPageHeader = () => {
  return (
    <div id='website-responsive' style={{ boxShadow:'0 6px 12px 0 rgba(0,0,0,.5)', borderStyle:'solid', borderColor:'#FF379F', borderWidth:'0 0 6px', zIndex:1, padding:'10px 0'}}>
        <div className='flex-center5 mx-md-5 mx-4 py-2' >
            <div className=''>
                <img src='/logo/Valley-Pediatrics-Header-Logo.webp' alt='Logo' height='' width='' className='img-w14'/>
            </div>

            <div className='d-block d-md-none d-xl-block'>
            <div className='flex-center'>
                <div className='display-none1 flex-center me-5'>
                    <a target='_blank' className='text-decoration-none' href='https://www.google.com/maps/place/Valley+Medical/@34.3454038,-86.3068594,17z/data=!4m2!3m1!1s0x8889f934d22a25b9:0x88839cfbc9bb566c'>
                    <GeoAltFill className='text-ss-primary me-2 fs-ss-16' />
                    <text className='fs-ss-16 text-roboto fw-500 text-ss-black'>
                        1436 Gunter Ave, Guntersville, AL 35976, US
                    </text>
                    </a>
                </div>

                <div className='ms-md-4 me-md-5'>
                {/* <center> */}
                <a href='tel:+12562021111'>
                <Button className='btn-ss-primary btn-padding' style={{ borderRadius:'25px 25px 25px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)',}}>
                    <text className='fs-ss-20 text-roboto fw-600'>
                        (256) 202-1111
                    </text>
                </Button>
                </a>
                {/* </center> */}
            </div>
            </div>
            </div>

            
            <div className='d-none d-md-block d-xl-none'>
            <div className=''>
                <div className=''>
                    <a target='_blank' className='text-decoration-none' href='https://www.google.com/maps/place/Valley+Medical/@34.3454038,-86.3068594,17z/data=!4m2!3m1!1s0x8889f934d22a25b9:0x88839cfbc9bb566c'>
                    <GeoAltFill className='text-ss-primary me-2 fs-ss-16' />
                    <text className='fs-ss-16 text-roboto fw-500 text-ss-black'>
                        1436 Gunter Ave, Guntersville, AL 35976, US
                    </text>
                    </a>
                </div>

                <div className='text-center pt-2'>
                {/* <center> */}
                <a href='tel:+12562021111'>
                <Button className='btn-ss-primary btn-padding' style={{ borderRadius:'25px 25px 25px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)',}}>
                    <text className='fs-ss-20 text-roboto fw-600'>
                        (256) 202-1111
                    </text>
                </Button>
                </a>
                {/* </center> */}
            </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default AdsPageHeader