import React from 'react'
import { Button } from 'reactstrap'
import image1 from '../../assets/images/about/About-Middle-Section-Pic-webp.webp'

const AboutChildHealth = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img d-none d-md-block' style={{ backgroundImage: "url('/about-us/jj.png')", height:'100%', width:'100%' }}>
            <div className='about-text-padding1 '>
            <div>
                <h3 className='fs-ss-32 text-raleway fw-700 text-ss-primary'>
                    Make the right choice for your
                </h3>
            </div>

            <div>
                <h3 className='fs-ss-58 text-raleway fw-900' style={{ color:'#484848' }}>
                    CHILD'S HEALTH
                </h3>
            </div>

            <div className='about-img-text-padding'>
                <a href='tel:+12562021111'>
                <Button className='btn-ss-primary btn-hover' style={{ borderRadius:'25px 25px 25px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)', padding:'6px 30px'}}>
                    <text className='fs-ss-22 text-roboto fw-600'>
                        (256) 202 1111
                    </text>
                </Button>
                </a>
            </div>
        </div>
        </div>

        <div className='bg-img display-block1' style={{ backgroundImage: 'url("/about-us/About-Middle-Section-bg-webp.webp")', height:'100%', width:'100%' }}>
            <div className='about-text-padding2 display-block1'>
            <div>
                <text className='fs-ss-32 text-raleway fw-700 text-ss-primary lh-1-2'>
                    <center>
                        Make the right choice for <br/> your
                    </center>
                </text>
            </div>

            <div>
                <text className='fs-ss-58 text-raleway fw-900' style={{ color:'#484848' }}>
                   <center>
                        CHILD'S HEALTH
                    </center>
                </text>
            </div>

            <div className='pt-5'>
                <center>
                <a href='tel:+12562021111'>
                <Button className='btn-ss-primary' style={{ borderRadius:'25px 25px 25px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)', padding:'8px 30px'}}>
                    <text className='fs-ss-22 text-roboto fw-600'>
                        (256) 202 1111
                    </text>
                </Button>
                </a>
                </center>
            </div>

            <div className='pt-5'>
                <img src={image1} alt='pic' width='100%'  />
            </div>
        </div>
        </div>
    </div>
  )
}

export default AboutChildHealth