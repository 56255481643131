import React from 'react'

const AboutMain = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img1 about-text-padding' style={{ backgroundImage: 'url("/about-us/About-Banner-500px.webp")', height:'100%', width:'100%' }}>
            <div className='about-max-width'>
                <h1 className='fs-ss-50 text-oswald fw-800 lh-1-2 text-black'>
                    About Valley Pediatrics
                </h1>
            </div>
        </div>
    </div>
  )
}

export default AboutMain