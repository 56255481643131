import React from 'react'
import { Col, Row } from 'reactstrap'

const SickVisitsCovidTesting = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 p-top-bottom2rem1'>
            <Row className='gy-5'>
                <Col md='11' xl='6' className='pe-md-5'>
                    <div className='pb-md-3 pb-1'>
                        <h3 className='fs-ss-32 text-raleway fw-700 text-ss-primary'>
                            Covid Testing
                        </h3>
                    </div>

                    <div>
                        <span className='fs-ss-16 text-roboto lh-1-8'>
                            The pandemic has offered a whole new set of complications in the health of those whom we love. Valley pediatrics understands the anxiety that can be caused when faced with the fear of Covid-19. This is why we offer covid-19 testing solutions in Guntersville. Whether you need rapid or RT PCR testing, our expert providers are fully equipped to ensure quick results from a reliable lab. Bringing fast and dependable test results in Guntersville for you and your little ones!
                        </span>
                    </div>
                </Col>
                <Col md='11' xl='6'>
                    <div className='flex-center pt-md-0 pt-4'>
                        <img src="/sick-visit/Sick-Visits-children-pic.webp" alt='Covid testing' className='img-w8' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default SickVisitsCovidTesting