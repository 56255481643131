import React from 'react'
import { Col, Row } from 'reactstrap'

const PsychiatryNurses = () => {
  return (
    <div id='website-responsive'>
        <div className='pb-md-4 pt-md-0 pt-4'>
        <Row className='pb-xl-5 pb-4 left-padding7rem right-padding7rem'>
                    <Col md='11' xl='8' className='m'>
                        <div>
                            <h3 className='fs-ss-28-3 text-raleway fw-700 text-ss-primary'>
                                Keaton Aldrup, MHNP
                                <hr className='hr-style' style={{ width:'60px', marginTop:'0px', marginBottom:'4px'}}/>
                            </h3>
                        </div>

                        <div className='pb-3'>
                            <h3 className='text-raleway fw-600 fs-ss-24-1'>   
                                Mental Health Nurse Practitioner
                            </h3>
                        </div>

                        <div style={{ textAlign:'justify'}}>
                            <p className='fs-ss-16-2 text-roboto lh-1-8'>
                                Our expert care provider, Mr. Josiah Aldrup is a Psychiatric Mental Health Nurse Practitioner specializing in treating mental health issues. He graduated from the University of Alabama Huntsville in 2013 with a BSN and later attended the University of Alabama at Birmingham in 2017 with an MSN. Mr. Aldrup has experience treating a multitude of conditions, including but not limited to mood disorders, ADHD, anxiety disorders, schizophrenia, PTSD, and OCD. He treats patients of all ages and has experience in inpatient and outpatient settings. Before becoming a nurse practitioner, he worked as an inpatient psychiatric nurse for several years, working with severely mentally ill adult and adolescent patients. Mr. Aldrup primarily provides mental health evaluations and medication management services. 
                                <br/><br/>
                                With such care providers at Valley Pediatrics, we are committed to providing high-quality, personalized behavioral health services to help children and families achieve optimal health and well-being.
                            </p>
                        </div>
                    </Col>

                    <Col xs='9' xl='4' className='pt-xl-5 pt-3 mx-auto' style={{ display:'flex', justifyContent:'center', alignItems:'baseline'}}>
                        <img src='/psych-visit/Valley-Psychiatry-Keaton-Aldrup-pic1.webp' alt='Providers' width='' height='auto' className='about-p-img1 img-w11' />
                    </Col>
                </Row>
        </div>
    </div>
  )
}

export default PsychiatryNurses