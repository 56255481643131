import React from 'react'
import { TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'

const PsychiatryCallNow = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img p-top-bottom2rem ' style={{ backgroundImage:'url("/psych-visit/Pediatrics-Clinic-Boaz-Middle-Section-bg-v1.webp")', height:'100%', width:'100%' }}>
            <div className='pb-4'>
                <h3 className='fs-ss-40 text-raleway fw-700 lh-1-2'>
                    <center>
                        Talk With Us And Know How We Can Help You
                    </center>
                </h3>
            </div>

            <div className='left-padding10rem1 right-padding10rem1 pb-5 pt-1'>
                <p className='fs-ss-26 text-raleway fw-500 lh-1-2'>
                    <center>
                        The team here at Valley Pediatrics is dedicated to providing the safest and most supportive services to assist with mental illness recovery. Call us today!
                    </center>
                </p>
            </div>

            <div className='pb-4'>
                <p className='text-ss-primary fs-ss-28 text-montserrat fw-700 lh-1-2'>
                    <center>
                        Mon - Fri (8:30AM - 5:00PM)
                    </center>
                </p>
            </div>

            <div className='pt-3'>
                <a href='tel:+12562021111'>
                <center>
                    <Button className='btn-ss-primar btn-hover rounded30 flex-center' style={{ border:'none',  backgroundColor:'white', padding:'6px 30px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)', }}>
                        <div className='flex-center'>
                            <TelephoneFill className='me-2 fs-ss-22 text-roboto text-ss-primary' />
                            <span className='fs-ss-22 text-roboto fw-600 text-ss-primary'>(256) 202 1111</span>
                        </div>
                    </Button>
                </center>
                </a>
            </div>
        </div>
    </div>
  )
}

export default PsychiatryCallNow