import React from 'react'
import { TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'

const InsAndFinCallNow = () => {
  return (
    <div id='website-responsive'>
        <div className='py-md-5 py-4'>
            <div className='pb-md-4 pb-2 pt-2 '>
                <h2 className='fs-ss-32 text-raleway fw-800 text-ss-primary' style={{ letterSpacing:'0.9px'}}>
                    <center>
                        JUST A CALL AWAY!
                    </center>
                </h2>
            </div>

            <div className='pb-4'>
                <p className='fs-ss-28 fw-700 text-raleway lh-1-2'>
                    <center>
                        On the path to your child's health,
                        <br/>
                        reach out to our providers at Valley Pediatrics.
                    </center>
                </p>
            </div>

            <div className='pb-md-5 pb-4'>
            <center>
                <a target='_blank' rel='noreferrer' href='tel:+12562021111'>
                    <Button className='btn-ss-primary rounded30 btn-hover' style={{ padding:'6px 25px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)'}}>
                        <div className='flex-center'>
                            <TelephoneFill className='fs-ss-22 fw-600 text-roboto me-2' />
                            <text className='fs-ss-22-1 fw-600 text-roboto'>(256) 202 1111</text>
                        </div>
                    </Button>
                </a>
            </center>
            </div>
        </div>
    </div>
  )
}

export default InsAndFinCallNow