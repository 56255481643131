import React from 'react'
import Img1 from "../../../assets/images/ads/Pediatrics-Clinic-near-Albertville-Banner-v1.webp"

const AlbertvilleMain = () => {
  return (
    <div id='website-responsive' className='bg-img ' style={{ backgroundImage:`url(${Img1})` ,height:'', width:'100%'}}>
    <div className='albert-text-padding bg-img-h'>
    <div className='albert-max-width'>
            <text className='fs-ss-44-1 text-oswald fw-800 lh-1-2 text-ss-black'>
            Pediatric Clinic <br/> Near Albertville
            </text>
        </div>
    </div>
</div>
  )
}

export default AlbertvilleMain