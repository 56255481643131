import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import HomeWelcome from '../components/home/HomeWelcome'
import HomeAppointment from '../components/home/HomeAppointment'
import CopyRights from '../components/layout/CopyRights'
import HomeNewPatients from '../components/home/HomeNewPatients'
import HomeCaringHands from '../components/home/HomeCaringHands'
import HomeWeAreHere from '../components/home/HomeWeAreHere'
import HomeLocation from '../components/home/HomeLocation'
import HomeFaq from '../components/home/HomeFaq'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HomeBanner from '../components/home/HomeBanner'


const HomPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>

        <HelmetProvider>
            <Helmet prioritizeSeoTags>
              <title>Pediatrics Clinic in Guntersville &ndash; Valley Pediatrics | Kids Doctor</title>
              {/* <link rel="preload" as="font" href="https://www.valley-pediatrics.com/plugins/elementor/assets/lib/eicons/fonts/eicons.woff2?5.14.0" crossorigin /> */}

              {/* <link rel="preload" as="font" href="https://www.valley-pediatrics.com/themes/pediatric/fonts/WooCommerce.woff" crossorigin /> */}

              <link rel="preload" as="font" href="https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459Wlhyw.woff2" crossorigin />

              <meta name="description" content="Ensure the best care for your child with a great pediatrics clinic in Guntersville. Visit Valley Pediatrics now!" />
              
              <link rel="canonical" href="https://www.valley-pediatrics.com/" />
              <meta property="og:locale" content="en_US" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content="Pediatrics Clinic in Guntersville &ndash; Valley Pediatrics | Kids Doctor" />
              <meta property="og:description" content="Ensure the best care for your child with a great pediatrics clinic in Guntersville. Visit Valley Pediatrics now!" />
              <meta property="og:url" content="https://www.valley-pediatrics.com/" />
              <meta property="og:site_name" content="Valley Pediatrics" />
              <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
              <meta property="article:modified_time" content="2023-05-03T10:42:57+00:00" />
              <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
              <meta property="og:image:type" content="image/png" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
                        </Helmet>
                    </HelmetProvider>
      
        <TopHeader/>
        <MainHeader/>
        <HomeBanner/>
        <HomeWelcome/>
        <HomeAppointment/>
        <HomeNewPatients/>
        <HomeCaringHands/>
        <HomeWeAreHere/>
        <HomeFaq/>
        <HomeLocation/>

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default HomPage