import React from 'react'
import {  Col, Row } from 'reactstrap'

const HomeAppointment = () => {
  return (
    <div id='website-responsive' style={{ backgroundImage:'url("home/home-middle-section-webp.webp")', backgroundSize:'cover' , backgroundPosition:'top' ,backgroundRepeat:'no-repeat', height:'100%', width:'100%', display:'grid', justifyContent:'center', alignItems:'center', paddingTop:'', paddingBottom:'' }}>
    <div className='padding2-2xem p-md-5 p-3 pt-5'>
    <Row className=''>
    <Col md='6'>
    <div className='grid-center pt-md-5'>
    {/* <div style={{ backgroundImage: 'url("https://www.valley-pediatrics.com/uploads/2022/03/Offering-Same-Day-Appointments.png")', backgroundPosition:'50px 60px', backgroundRepeat:'no-repeat', backgroundSize:'80% auto' }}></div> */}
    <img src='home/Offering-Same-Day-Appointments.png' alt='Same Day Appointment' width='80%'  className=' img6-margin'/>
    <center>
    <div className='max-width pt-md-3 pt-4' style={{ backgroundColor:'',}}>
    <text className='fs-ss-24 fw-700 text-montserrat' style={{ color:'', }}>
        <center>
            <>
            Mon - Fri  (8:30AM - 5:00PM) 
            </>
        </center>
    </text>
    </div>
    </center>
<center>
    <div>
        <center>
        <div className='mt-5 mb-3 bg-img' style={{ backgroundImage:'url(/home/GreenTape.png)', height:'100%', width:'45%', transform:'rotateZ(-4deg)' }}>
        <a href='tel:+12562021111' style={{ textDecoration:'none' }}>
            {/* <Button className='btn-ss-secondary rounded10' > */}
            <text className='fs-ss-28 text-roboto text-ss-primary fw-600'>
                256 202 1111
            </text>
            {/* </Button> */}
        </a>    
        </div>
        </center>
    </div>
    </center>
    </div>
    {/* </div> */}
    </Col>

    <Col md='6'>
        <div className='flex-center'>
        <img src='home/Home-page-pic1.png' alt='Two children sitting comfortably at our pediatrics clinic in Guntersville' width='' height='auto' className='img-app' />
        </div>
    </Col>
    </Row>
    </div>
</div>
  )
}

export default HomeAppointment