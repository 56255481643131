import React,{useEffect} from 'react'
import TopHeader from '../../components/layout/TopHeader'
import MainHeader from '../../components/layout/MainHeader'
import Footer from '../../components/layout/Footer'
import BlogsMain from '../../components/blogs/BlogsMain'
import BlogsTopics from '../../components/blogs/BlogsTopics'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const BlogsPage = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>
      <HelmetProvider>
          <Helmet>
          <title>Blog &ndash; Valley Pediatrics</title>
          <link rel="canonical" href="https://www.valley-pediatrics.com/blog/" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Blog &ndash; Valley Pediatrics" />
          <meta property="og:description" content="Valley Pediatrics Blog Valley Pediatrics Blog June 8, 2022 Fever in Children: When does? Fever is one of the most concerning medical symptoms that parents have to experience with their children. Most often, fever resolves on its own, but sometimes it requires treatment by professionals. It has been observed that many new parents consider even [&hellip;]" />
          <meta property="og:url" content="https://www.valley-pediatrics.com/blog/" />
          <meta property="og:site_name" content="Valley Pediatrics" />
          <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
          <meta property="article:modified_time" content="2022-06-11T19:58:52+00:00" />
          <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="2 minutes" />
          </Helmet>
      </HelmetProvider>
      <TopHeader/>
      <MainHeader/>

      <BlogsMain/>
      <BlogsTopics/>
      <Footer/>



    </div>
  )
}

export default BlogsPage