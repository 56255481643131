import React from 'react'
import { PhoneFill, TelephoneFill } from 'react-bootstrap-icons'
import { Button } from 'reactstrap'

const SickVisitsAppointmets = () => {
  return (
    <div id='website-responsive'>
        <div className='bg-img ' style={{ backgroundImage: 'url("/sick-visit/Sick-Visits-Middle-Section-bg-v1.png")', height:'100%', width:'100%', }}>
            <div className='about-t-bg' style={{ backgroundImage:'url("/sick-visit/Sick-Visits-Middle-Section-pic-v2.png")', }}>
            <div style={{ }} className='about-t-padding '>
            <div className=''>
                <center>
                <p className='fs-ss-28 text-roboto fw-500 text-white lh-1'>
                    A child's sickness can be spontaneous and needs immediate attention.
                    <br/>
                    That is why we're offering same-day sick visits now!
                </p>
                </center>
            </div>

            <div className='py-5'>
            <center>
                <h3 className='fs-ss-32 text-raleway fw-700 lh-1'>
                    Call us to schedule an appointment for your child today!
                </h3>
            </center>
            </div>

            <div className=''>
                <a href='tel:+12562021111'>
                <center>
                    <Button className='btn-ss-primary rounded30 btn-hover flex-center' style={{ padding:'6px 30px', boxShadow:'0 2px 10px 0 rgba(0,0,0,.25)' }}>
                        <div className='flex-center'>
                            <TelephoneFill className='me-2 fs-ss-22 text-roboto' />
                            <text className='fs-ss-22 text-roboto fw-600'>(256) 202 1111</text>
                        </div>
                    </Button>
                </center>
                </a>
            </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default SickVisitsAppointmets