import React from 'react'
import { Col, Row } from 'reactstrap'
import Img1 from '../../../assets/images/arab/Pediatric-Clinic-near-Arab-pic1.webp'

const ArabSickVisit = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <Row className=''>
                <Col md='8' className=''>
                    <div className=''>
                        <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                            Pediatric Sick Visits Near Arab, Al
                        </text>
                    </div>

                    <div className='py-2'>
                        <text className='fs-ss-22-2 fw-700 text-raleway text-ss-black lh-1-2'>
                            You can count on us to be there
                        </text>
                    </div>


                    <div className='py-2'>
                        <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black '>
                            Children get sick pretty frequently, and because we care about them, we will be there for them when they need us. Choosing whether or not your child’s condition necessitates a trip to the doctor might be challenging at times. We advise you to follow your gut feelings and visit a pediatrician anytime you’re unsure. Your child’s symptoms, such as rashes, sore throats, ear infections, etc., will be assessed by our team of skilled practitioners who will then assist in treating your sick child. Be aware that some symptoms require rapid attention; don’t put it off. If your kid experiences any of the following conditions or unusual symptoms, call us or come in for a visit:
                        </text>
                    </div>
                   
                    <div className=''>
                        <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            Call us or come for a visit if your child has any of the conditions below or something out of routine:
                        </text>
                        <ul style={{ padding:'16px', listStyleType:'square'}} className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                            <li className=''>A fever between 104.0°F and 105.0°F</li>
                            <li className=''>A fever that lasts longer than three days in a succession, whether or not there is an obvious source of infection</li>
                            <li className=''>Having a fever and a sore throat for more than 24 to 48 hours</li>
                            <li className=''>Your child develops a rash, is lethargic, won't eat, or has other symptoms, such as breathing issues</li>
                            <li className=''>Your child may exhibit signs of dehydration such as a dry mouth, less wet diapers, or more frequent urination</li>
                        </ul>
                    </div>
                </Col>

                <Col md='4' className=''>
                    <div className='pt-md-5 pt-'>
                        <img src={Img1} alt='Sick Visit Boaz' height='auto' width='100%' className=''  />
                    </div>
                </Col>
            </Row>

            <div className='pt-4 pt-md-0'>
                <div className='pb-md-3 pb-2'>
                    <text className='fs-ss-28-2 text-raleway text-ss-black fw-700 lh-1-2'>
                        At the Clinic, Expect the Following
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Your child’s symptoms, your family’s medical history, and any other details that might be relevant to figuring out your child’s illness will all be discussed with your healthcare provider. The physical examination would come next, which would involve taking the child’s temperature, keeping an eye on their respiration and heartbeat, and searching for any signs of an infection.
                    </text>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ArabSickVisit