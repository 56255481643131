import React, { useEffect } from 'react'
import TopHeader from '../components/layout/TopHeader'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import CopyRights from '../components/layout/CopyRights'
import WellVisitMain from '../components/well-visit/WellVisitMain'
import WellVisitFirstChoice from '../components/well-visit/WellVisitFirstChoice'
import WellVisitClinic from '../components/well-visit/WellVisitClinic'
import WellVisitPediatrics from '../components/well-visit/WellVisitPediatrics'
import WellVisitInfo from '../components/well-visit/WellVisitInfo'
import WellVisitPdf from '../components/well-visit/WellVisitPdf'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const WellVisitPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
},[])

  return (
    <div>
        <HelmetProvider>
            <Helmet>
            <title>Pediatric Well Visits in Guntersville &ndash; Valley Pediatrics</title>
            <meta name="description" content="Children need to have their health monitored to ensure an appropriate growth rate. Valley Pediatrics has the best pediatric well visits in Guntersville." />
            <link rel="canonical" href="https://www.valley-pediatrics.com/well-visits/" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Pediatric Well Visits in Guntersville &ndash; Valley Pediatrics" />
            <meta property="og:description" content="Children need to have their health monitored to ensure an appropriate growth rate. Valley Pediatrics has the best pediatric well visits in Guntersville." />
            <meta property="og:url" content="https://www.valley-pediatrics.com/well-visits/" />
            <meta property="og:site_name" content="Valley Pediatrics" />
            <meta property="article:publisher" content="https://www.facebook.com/valleypediatrics/" />
            <meta property="article:modified_time" content="2022-05-07T11:36:12+00:00" />
            <meta property="og:image" content="https://www.valley-pediatrics.com/logo/valley-pediatrics-logo-1024x392.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="4 minutes" />
            </Helmet>
        </HelmetProvider>
        <TopHeader/>
        <MainHeader/>

        <WellVisitMain/>
        <WellVisitFirstChoice/>
        <WellVisitClinic/>
        <WellVisitPediatrics/>
        <WellVisitInfo/>
        <WellVisitPdf/> 
        

        <Footer/>
        <CopyRights/>
    </div>
  )
}

export default WellVisitPage