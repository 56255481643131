import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import Icon1 from '../../../assets/images/ads/Icon1.png'
import Icon2 from '../../../assets/images/ads/Icon2.png'
import Icon3 from '../../../assets/images/ads/Icon3.png'

const BoazChildCare = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5'>
          <div className='pt-3 pb-2'>
                <text className='fs-ss-32-1 text-raleway fw-700 lh-1 text-ss-black'>
                    Your Best Child Care Option
                </text>  
          </div>

          <div className=''>
                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                    <Link style={{ textDecoration:'none'}} className='text-ss-primary' to='/' >Valley Pediatrics </Link> is a family pediatric clinic near Boaz. Our care team features the best pediatricians and professional practice providers passionate about helping children stay well so they can play, grow, learn and achieve their dreams. For that, our pediatricians and nurses ensure children get the comprehensive care they need to thrive. We always find a time that suits you, whether a sick urgent visit or a regular well-child checkup. We follow the ‘no patients waiting for appointment’ policy and provide quick and easy access to high-quality pediatric care as a walk-in pediatric clinic near Boaz. We believe in providing a combination of clinical expertise and a calm, reassuring approach to your child’s health and well-being.
                </text>  
          </div>


          <div className='pt-5 pb-3 '>
                <text className='fs-ss-32-2 text-raleway fw-700 text-ss-black lh-1-2'>
                    Providing Care Every Step of the Way
                </text>  
          </div>

          <div className='pb-2'>
                <text className='fs-ss-16-2 text-roboto lh-1-8'>
                    At Valley Pediatrics, you find the right care and a chance to connect with the best healthcare professional providers all under one roof. We’re here to help you get a better care experience across each step of your healthcare journey, making healthcare more affordable, convenient, and reliable.
                </text>  
          </div>

          <div className='py-5'>
                <Row className='text-center'>
                    <Col sm='12' md='4' className='pb-4'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon1} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Same Day Sick Visits</text>
                        </div>
                    </Col>

                    <Col sm='12' md='4' className='py-4 py-md-0'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon2} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Most Insurances Accepted</text>
                        </div>
                    </Col>

                    <Col sm='12' md='4' className='py-4 py-md-0'>
                        <div className='grid-center'>
                        <div className='flex-center pb-3'>
                        <img src={Icon3} alt='Sick visit' height='auto' width='90px'  />
                        </div>
                        <text className='fs-ss-18-2 text-roboto text-ss-black fw-600'>Accepting New Patients</text>
                        </div>
                    </Col>
                </Row>
            </div>


        </div>
    </div>
  )
}

export default BoazChildCare