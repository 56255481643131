import React from 'react'
import { Col, Row } from 'reactstrap'
import Image1 from '../../../assets/images/Pediatric-Clinic-Boaz-pic3-v1.jpg'

const BoazWeAreHere = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
            <Row>
                <Col md='11' xl='7' className=''>
                    <div className=''>
                        <div className='py-3'>
                            <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                We Are Here For You
                            </text>
                        </div>

                        <div className=''>
                            <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                At our pediatric walk-in clinic near Boaz, pediatricians specialize in providing comprehensive healthcare services, including:

                                <ul className='' style={{ padding:'16px', listStyleType:'square'}}>
                                    <li className=''>Administering recommended childhood immunization</li>
                                    <li className=''>Conducting newborn checkups, well-childcare, same-day sick visits, and sports physicals</li>
                                    <li className=''>Diagnosing and treating acute illnesses and injuries</li>
                                    <li className=''>Educating children and parents about the requirements of a healthy lifestyle and disease prevention, including proper nutrition, exercise, and sleep habits</li>
                                    <li className=''>Developmental screening by monitoring children’s milestones and signs of developmental delays</li>
                                    <li className=''>Evaluating behavioral development; guiding children and families how to control symptoms and minimize risk factors</li>
                                </ul>
                            </text>
                        </div>
                    </div>
                </Col>

                <Col md='11' xl='5' className='pt-md-3'>
                    <div className='pt-xl-5'>
                        <img src={Image1} alt='We are here' height='auto' width='100%' />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default BoazWeAreHere