
import '../assets/css/style.css'

import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import HomPage from '../pages/HomPage'
import AboutPage from '../pages/AboutPage'
import SickVisitsPage from '../pages/SickVisitsPage'
import WellVisitPage from '../pages/WellVisitPage'
import DoseChartsPage from '../pages/DoseChartsPage'
import VaccineSchedulePage from '../pages/VaccineSchedulePage'
import InsuranceAndFinancialPage from '../pages/InsuranceAndFinancialPage'
import PatientFormPage from '../pages/PatientFormPage'
import PsychiatryPage from '../pages/PsychiatryPage'
import ContactUspage from '../pages/ContactUspage'
import AdsPage from '../pages/AdsPage'
import BoazCityPage from '../pages/city-page/BoazCityPage'
import AlbertvilleCityPage from '../pages/city-page/AlbertvilleCityPage'
import ArabCityPage from '../pages/city-page/ArabCityPage'
import BlogsPage from '../pages/blogs/BlogsPage'
import BlogsContent from '../pages/blogs/BlogsContent'
import Error404 from '../pages/Error404'


const Path = () => {
  return (
    <div>
        <Routes>
            <Route path='/' exact element={<HomPage/>} />   
            <Route path='/about' exact element={<AboutPage/>} />        
            <Route path='/about-us' element={ <Navigate replace to="/about" /> } />
            <Route path='/sick-visits' element={<SickVisitsPage/>} />
            <Route path='/well-visits' element={<WellVisitPage/>} />
            <Route path='/dose-charts' element={<DoseChartsPage/>} />
            <Route path='/vaccine-schedule-and-information' element={<VaccineSchedulePage/>} />
            <Route path='/insurance-and-financial-information' element={<InsuranceAndFinancialPage/>} />
            <Route path='/patients-forms' element={<PatientFormPage/>} />
            <Route path='/valley-psychiatry' element={<PsychiatryPage/>} />
            <Route path='/contact-us' element={<ContactUspage/>} />
            <Route path='/blog' element={<BlogsPage/>} />
            <Route path='/blogs/:slug' element={<BlogsContent/>} />
            <Route path='/7-ways-to-help-kids-with-a-common-cold' element={ <Navigate to="/blogs/7-ways-to-help-kids-with-a-common-cold" /> }/>
            <Route path='/screen-time-what-are-the-issues-surrounding-too-much-screen-time' element={ <Navigate to="/blogs/screen-time-what-are-the-issues-surrounding-too-much-screen-time" /> }/>
            <Route path='/keeping-kids-safe-in-the-heat' element={ <Navigate to="/blogs/keeping-kids-safe-in-the-heat" /> }/>
            <Route path='/covid-in-children' element={ <Navigate to="/blogs/covid-in-children" /> }/>
            <Route path='/fever-in-children-when-does-it-become-a-concern' element={ <Navigate to="/blogs/fever-in-children-when-does-it-become-a-concern" /> }/>
            {/* Ads Landing Pages */}
            <Route path='/pediatric-healthcare' element={<AdsPage/>} />
            <Route path='/pediatric-clinic-near-boaz' element={<BoazCityPage/>} />
            <Route path='/pediatric-clinic-near-albertville' element={<AlbertvilleCityPage/>} />
            <Route path='/pediatric-clinic-near-arab' element={<ArabCityPage/>} />
            <Route path='*' element={<Error404/>} />
        </Routes>
    </div>
  )
}

export default Path