import React from 'react'

const WellVisitClinic = () => {
  return (
    <div id='website-responsive'>
    <div className='bg-img ' style={{ backgroundImage: 'url("/well-visit/well-visit.png")', height:'100%', width:'100%', }}>
        <div style={{ }} className='p-tb2 p-top-bottom2rem2'>
        <div className=''>
            <center>
            <p className='fs-ss-32-1 text-roboto fw-500 text-white lh-1-2'>
                Visit our pediatric clinic in Guntersville
                <br/>
                to give your young ones a
            </p>
            </center>
        </div>

        <div className='py-md-5 pt-3 pb-5'>
        <center>
            <img src='/well-visit/brighter today and tomorrow!.png' alt='well-visit' className='img-w10' />
        </center>
        </div>

        <div className='pt-4 ' style={{ transform:'rotate()' }}>
            <a href='tel:+12562021111' style={{ textDecoration:'none'}}>
            <center>
                <div className='bg-img p-3 img-w9' style={{ backgroundImage: 'url(/well-visit/Layer2.png)', backgroundSize:'', width:''}}>
                    <span className='fs-ss-28-2 text-roboto fw-600 text-white'>
                        256 202 1111
                    </span>
                </div>
            </center>
            </a>
        </div>
    </div>
    </div>
</div>
  )
}

export default WellVisitClinic