import React from 'react'
import { Col, Row } from 'reactstrap'

const PsychiatryPeaceOfMind = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding6rem1 right-padding6rem1 p-top-bottom2rem py-5'>
            <div className='pt-md-3'>
                <Row className=''>
                    <Col md='11' xl='4' className='pb-4'>
                    <div className='pe-md-2'>
                        <div className='pb-4'>
                            <img src='/psych-visit/Valley-Psychiatry-icon7.webp' alt='Peace of mind' height='80px' width='65px' />
                        </div>

                        <div className=''>
                            <h4 className='fs-ss-22-2 lh-1-2 text-ss-primary fw-700 text-raleway'>
                                DRIVEN TO BRING YOU PEACE OF MIND
                            </h4>
                        </div>

                        <div className=''>
                            <p className='fs-ss-15 text-roboto lh-1-8'>
                                When providing services for psycho-social recovery, we continuously uphold the highest standards. We work to maximize a person's social, emotional, and personal well-being to support them in getting remarkable results for their mental health.
                            </p>
                        </div>
                        </div>
                    </Col>

                    <Col md='11' xl='4' className='pb-4 pt-2 pt-md-0'>
                    <div className='pe-md-2'>
                        <div className='pb-4'>
                            <img src='/psych-visit/Valley-Psychiatry-icon8.webp' alt='Peace of mind' height='77px' width='65px' />
                        </div>

                        <div className=''>
                            <h4 className='fs-ss-22-2 lh-1-2 text-ss-primary fw-700 text-raleway'>
                                SUPPORTING PATIENTS IN THE LONG RUN
                            </h4>
                        </div>

                        <div className=''>
                            <p className='fs-ss-15 text-roboto lh-1-8'>
                                We collaborate and make decisions to establish goals, determine support requirements, create and carry out action plans, and monitor progress. Our mission is to effectively deliver a long-term social service that aids a person in achieving the objectives that will enable them to lead a happy, meaningful, and purposeful life.
                            </p>
                        </div>
                        </div>
                    </Col>

                    <Col md='11' xl='4' className='pt-2 pt-md-0'>
                    <div className='pe-md-2'>
                        <div className='pb-4'>
                            <img src='/psych-visit/Valley-Psychiatry-icon9.webp' alt='Peace of mind' height='77px' width='50px' />
                        </div>

                        <div className=''>
                            <h4 className='fs-ss-22-2 lh-1-2 text-ss-primary fw-700 text-raleway'>
                                TREATING PEOPLE AS INDIVIDUALS
                            </h4>
                        </div>

                        <div className=''>
                            <p className='fs-ss-15 text-roboto lh-1-8'>
                                We acknowledge that every individual is an expert of their own life and value the privilege of walking alongside a person through their recovery journey. We offer effective and evidence-based treatment for the full spectrum of acute mental health conditions.
                            </p>
                        </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
  )
}

export default PsychiatryPeaceOfMind