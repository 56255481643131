// import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ArrowLeft, Calendar, CheckSquare, ChevronDoubleRight } from 'react-bootstrap-icons'
import { Helmet } from 'react-helmet-async'
import { HelmetProvider } from 'react-helmet-async'
import MainHeader from '../../components/layout/MainHeader'
import Footer from '../../components/layout/Footer'
import { useParams } from 'react-router-dom'
import { Button, Col, Row } from 'reactstrap'
import axios from 'axios'
import moment from 'moment'
import TopHeader from '../../components/layout/TopHeader'
import CopyRights from '../../components/layout/CopyRights'

const BlogsContent = () => {

    const { slug } = useParams()
    const [blog, setBlog] = useState('')

    // get blog from blogs.json file  
    useEffect(() => {
        // read json file
        window.scrollTo(0, 0)
        axios.get('/blogs/blogs.json').then(res => {
            if (res.data) {
                // get blog from json file
                // find blog in json file by slug
                const blog = res.data.find(blog => blog.slug === slug)
                // set blog to state
                if(blog){
                    setBlog(blog)
                }else{
                    setBlog('')
                }
            }
        }).catch(err => {
            console.log(`error`, err);
            // console.log('error')
        })
    }
    , [])


    return (
        <>
        <HelmetProvider>
            <Helmet prioritizeSeoTags>
            <title>{blog?.title}</title>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={blog?.title} />
            <meta property="og:description" content={blog?.meta_description} />
            <meta property="og:site_name" content="Premier Medical Clinic" />
            <meta property="og:image" content={blog?.featured_image} />
            <meta property="og:image:width" content="1080" />
            <meta property="og:image:height" content="720" />
            <meta property="og:image:type" content="image/jpeg" />
            <meta name="author" content="premier" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Written by" />
            <meta name="twitter:data1" content="premier" />
            <meta name="twitter:label2" content="Est. reading time" />
            <meta name="twitter:data2" content="4 minutes" />
            </Helmet>
        </HelmetProvider>

        <TopHeader/>
        <MainHeader/>
        <div id='website-responsive' className='bg-light'>
            {/* {blogs} */}
            <div className='left-padding7rem right-padding7rem mb-3' style={{backgroundColor: 'white' }}>
                <div className='my-5 p-md-5 p-3 bg-light'>
                    <div className='text-center'>
                        <img src={`/blogs/${blog?.featured_image}`} alt={blog?.title} width='' className='blog-img' style={{  }} />
                    </div>
                    <h1 className='fs-zk-35 lh-zk text-zk-primary mt-4' style={{fontWeight:"600"}}>
                        {blog?.title}
                        <hr className='border-zk-black w-100px py-0 my-1'/>
                    </h1>
                    <div className='mt-3' dangerouslySetInnerHTML={{ __html: blog?.content }} />
                
                    <div className='text-zz-primary mt-md-4 fw-bold'>
                    {/* Uploaded  */}
                        Uploaded Date: <Calendar className='me-1' />{moment(blog?.created_at).format('MMMM DD, YYYY')}
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        <CopyRights/>
        </>
    )
}

export default BlogsContent