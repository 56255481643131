import React from 'react'

const PsychiatryMentalHealth = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem right-padding7rem1 py-md-5 p-top-responsive'>
            <div className='pb-3'>
                <h2 className='fs-ss-32 text-raleway fw-700 lh-1-2 text-ss-primary'>
                    Anybody Can Experience Mental Health Challenges
                </h2>
            </div>

            <div className='pb-2'>
                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                    Take a step toward personalized behavioral health. Regardless of age, talking about problems and emotions is not easy. That is where our experienced doctors play their part because we are dedicated to making a real difference in the lives of those we treat. At Valley Psychiatry in Guntersville, we have expertise in connecting with you, ensuring you feel comfortable and safe. We create relationship where patients of all ages can feel at ease while discussing anything.
                </p>
            </div>

            <div className='pt1'>
                <p className='fs-ss-16-2 text-roboto lh-1-8'>
                    The Valley Pediatric team specializes in individualized, person-centered, flexible support. We understand the complex challenges of living with a severe, persistent long-term mental illness and its impact on a person and their family. Our mission is to maintain the highest service delivery standards for psycho-social recovery consistently. Remember, a key part of moving towards wellness is finding providers who support you and make you feel comfortable through your recovery journey.
                </p>
            </div>
        </div>
    </div>
  )
} 

export default PsychiatryMentalHealth