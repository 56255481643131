import React from 'react'
import { Col, Row } from 'reactstrap'
import Image1 from '../../../assets/images/Pediatric-Clinic-Boaz-pic2-v1.jpg'

const BoazValleyPsychiatry = () => {
  return (
    <div id='website-responsive'>
        <div className='left-padding7rem1 right-padding7rem1 py-xl-5 py-4'>
            <div className=''>
                <Row className=''>
                    <Col md='11' xl='8' className='left-padding7rem1 right-padding7rem1 py-md-5 py-4'>
                        <div className='pe-xl-5'>
                            <div className=''>
                                <text className='fs-ss-32-3 text-raleway fw-700 lh-1-2 text-ss-primary'>
                                    Valley Psychiatry Boaz
                                </text>
                            </div>

                            <div className='py-2 '>
                                <text className='fs-ss-22-2 fw-700 text-raleway text-ss-black lh-1-2'>
                                    Navigating one care experience at a time
                                </text>
                            </div>

                            <div className='py-2 pe-md-5'>
                                <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                    Everyone can experience periods of stress, sadness, grief, and conflict, and you might require something more while passing through different developmental stages of your life. For some people, one or more life transition phases can be tricky and more painful than others. Such imbalances can lead to emotional and behavioral disruptions, causing a significant problem for your family. At such times, rest assured. Help is out there! It means you need some collaborative care involving professionals to resolve the issues. The care team at Valley Pediatrics are mental health experts who provide counseling to assist people to overcome a range of difficulties they might face in their everyday lives.
                                </text>
                            </div>

                            <div className='pe-md-5' >
                                <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                                    We offer comprehensive psychiatry services through collaborative care for Anxiety, Depression, ADHD, Bipolar Disorder, PTSD, OCD, and Mood & Mental Disorders near Boaz to give the care you deserve. We make sure to provide customized treatment to adults, adolescents, and children of all ages while understanding their medical requirements and needs.
                                </text>
                            </div>
                        </div>
                    </Col>

                    <Col md='11' xl='4' className='pt-md-3' style={{ display:'flex', justifyContent:'end'}}>
                        <div className='pt-md-5'>
                            <img src={Image1} alt='Valley' height='auto' width='100%' />
                        </div>
                    </Col>
                </Row>

                <div className='pt-5'>
                <div className='pb-md-3 pb-2'>
                    <text className='fs-ss-28-2 text-raleway text-ss-black fw-700 lh-1-2'>
                        How it works?
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        So, if your child is revealing signs of a mental health disorder: the inability to control emotions, disruptions in age-appropriate thinking or behavior, constant sadness, changes in sleeping or eating habits, or a decline in school performance, it is time to seek professional care as soon as possible.
                    </text>
                </div>

                <div className='pb-2'>
                    <text className='fs-ss-16-2 text-roboto lh-1-8 text-ss-black'>
                        Contact Valley Psychiatry for an evaluation, diagnosis, intervention, and treatment plan for children with their parents or guardians.
                    </text>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default BoazValleyPsychiatry