import React from 'react'
import { CalculatorFill, EnvelopeFill, Facebook, GeoAltFill, Instagram, TelephoneFill } from 'react-bootstrap-icons'
import { Button, Col, Form, FormFeedback, FormGroup, FormText, Input, Label, Row, Table } from 'reactstrap'
import InquiryForm from './InquiryForm'

const ContactUsInfo = () => {

  const handleSubmit = (e) => {
    e.preventDefault()

  }  
  return (
    <div id='website-responsive'  >
    <div className=' mb-5' style={{ backgroundColor:'',   }}>
        <Row className='reverse-section pt-5' style={{  padding:''}}>
            <Col md='6' lg="5" className='px-5 ms-auto'>
                <InquiryForm/>
                       </Col>

            <Col xs="11" md='6' lg="4" className='mx-auto' style={{  borderStyle:'', borderColor:''}}>

            <div style={{ marginBottom:'1.5rem',}}>
                <h2 className='fs-ss-32 text-raleway text-ss-black fw-700 ps-' style={{ }}>Get In Touch</h2>
                </div>
                <div >
                    {/* <Table bordered={true} style={{ backgroundColor:'none',  }}>
                        <tbody>
                            <tr style={{ border:'0px' }}>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7', fontWeight:'600'}}>Monday</td>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr style={{ border:'0px' }}>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7', fontWeight:'600'}}>Tuesday</td>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr style={{ border:'0px' }}>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7', fontWeight:'600'}}>Wedndesday</td>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr style={{ border:'0px' }}>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7', fontWeight:'600'}}>Thursday</td>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr style={{ border:'0px' }}>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7', fontWeight:'600'}}>Friday</td>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7'}}>8:30AM - 5:00PM</td>
                            </tr>
                            <tr style={{ border:'0px' }}>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7', fontWeight:'600'}}>Sat - Sun</td>
                                <td className='text-roboto fs-ss-16' style={{ background:'none', color:'black', border:'1px solid #f2f5f7',}}>8:30AM - 5:00PM</td>
                            </tr>
                        </tbody>
                    </Table> */}
                    
                    {/* <center style={{ marginTop:'3rem'}}>
                        <hr style={{ width:'150px', height:'5px', color:'#cacaca', opacity:'1', }}/>
                    </center> */}

                    <div className=''>
                        <div className='pb-4'>
                        <a target='_blank' rel='noreferrer' href='tel:+12562021111' style={{ textDecoration:'none'}}>
                            <Button className='btn-ss-primary' style={{ width:'100%', padding:'10px 30px', borderRadius:'30px 0px 0px 30px'}}>
                                <span className='flex-center2'>
                                    <TelephoneFill className='me-3 fs-ss-22 fw-500 text-roboto'/>
                                    <text className='fs-ss-20 fw-500 text-roboto'>(256) 202 1111</text>
                                </span>
                            </Button>
                        </a>
                        </div>

                        <div className='pb-4'>
                            <Button className='btn-ss-primary' style={{ width:'100%', padding:'10px 30px', borderRadius:'30px 0px 0px 30px'}}>
                                <span className='flex-center2'>
                                    <CalculatorFill className='me-3 fs-ss-22 fw-500 text-roboto'/>
                                    <text className='fs-ss-20 fw-500 text-roboto'>(256) 202 1112</text>
                                </span>
                            </Button>
                        </div>

                        <div className='pb-4'>
                        <a target='_blank' rel='noreferrer' href='mailto:info@valley-pediatrics.com' style={{ textDecoration:'none'}}>
                            <Button className='btn-ss-primary' style={{ width:'100%', padding:'10px 30px', borderRadius:'30px 0px 0px 30px'}}>
                                <span className='flex-center2'>
                                    <EnvelopeFill className='me-3 fs-ss-22 fw-500 text-roboto'/>
                                    <text className='fs-ss-20 fw-500 text-roboto'>info@valley-pediatrics.com</text>
                                </span>
                            </Button>
                        </a>
                        </div>

                        <div className='pb-4'>
                        <a target='_blank' rel='noreferrer' href='https://www.google.com/maps/place/Valley+Medical/@34.3454038,-86.3068594,17z/data=!3m1!4b1!4m5!3m4!1s0x8889f934d22a25b9:0x88839cfbc9bb566c!8m2!3d34.3453516!4d-86.3045964' style={{ textDecoration:'none'}}>
                            <Button className='btn-ss-primary' style={{ width:'100%', padding:'10px 30px', borderRadius:'30px 0px 0px 30px'}}>
                                <span className='flex-center2'>
                                    <GeoAltFill className='me-3 fs-ss-22 fw-500 text-roboto'/>
                                    <text className='fs-ss-20-1 fw-400 text-roboto'>1436 Gunter Ave, Guntersville, AL</text>
                                </span>
                            </Button>
                        </a>
                        </div>

                        
                    </div>

                    <div className='flex-center1' style={{ marginTop:'2rem', marginBottom:'1rem'}}>
                        <h3 className='text-raleway fs-ss-32 fw-700' style={{ fontSize:'', fontWeight:'', color:'#' }}>
                           Connect With Us
                        </h3>
                    </div>

                    <div className='flex-center1' style={{ marginRight:''}}>
                    <a href='https://www.facebook.com/valleypediatrics/' target='_blank' style={{ textDecoration:'none'}}>
                    <Facebook fontSize='1.5rem'  className='me-3 text-ss-black'/>
    
                    <text className='text-roboto text-ss-black' style={{ marginRight:'2rem'}}>Facebook</text>
                    </a>
                    {/* </div> */}
                    <a target='_blank' style={{ textDecoration:'none'}} href='https://www.instagram.com/valleypediatrics1/' >
                    <Instagram fontSize='1.5rem' className='me-3 text-ss-black'/>
    
                    <text className='text-roboto text-ss-black' style={{ marginRight:'2rem'}}>Instagram</text>
                    </a>   
                    </div>
                </div>
            </Col>
        </Row>
        </div>
        
    </div>
  )
}

export default ContactUsInfo