import React from 'react'

const HomeWelcome = () => {
  return (
    <div id='website-responsive' className='mt-'>
    <div className='left-padding8rem1 right-padding8rem1 py-md-4 '>
   <div className='text-align-center'>
     <text className='text-ss-primary fs-ss-28-2 text-raleway' ><><strong>We Care For Your Kids With Kindness </strong> </></text><br/>
     <div className='mb-md-2 mt-md-1 mt-2'><h2 className='text-raleway text-black fw-800 fs-ss-30-2 ' style={{ letterSpacing:'1px'}}><><>WELCOME TO OUR CLINIC</></></h2></div>
   </div>
 
 <div className='pt-3 pb-md-4 pb-2'>
     <span className='text-roboto fs-ss-16-2 lh-1-8' >
     Here at Valley Pediatrics, we are honored to serve the people as a pediatrics clinic in Guntersville and all surrounding areas since 2020. Aiding the residents of Guntersville in their children’s medical concerns is truly our priority and purpose. We are not only committed to helping your child grow into a healthy adult but also to ensuring that the children in our area experience safe medical care by employing evidence-based health practices with kindness and compassion. Therefore, our pediatric clinic in Guntersville aims to assure our patients’ parents that their input in the care of their kids is valued and respected. Moreover, we aim to enrich your life by increasing the quality of healthcare your children receive. Let our medical care experts help you in your efforts to keep your little ones healthy. We are eager to care for your loved ones!
     </span>
     </div>
     </div>
 </div>
  )
}

export default HomeWelcome