import React,{useEffect} from 'react'
import MainHeader from '../components/layout/MainHeader'
import Footer from '../components/layout/Footer'
import { Link } from 'react-router-dom'
import TopHeader from '../components/layout/TopHeader'
import CopyRights from '../components/layout/CopyRights'

const Error404 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        window.document.title = "Page Not Found - Valley Pediatrics"
    }, [])
    return (
        <>
        <TopHeader/>
        <MainHeader />
            <div className='bg-page' id="website-responsive">
                {/* <BannerCategory /> */}
                <section className='py-5'>
                    <div className='container-zk my-md-5 text-center'>
                        <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
                        <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>
                        <div className='mt-5'>
                            <Link to='/' className='btn btn-sm btn-ss-primary text-white pb-1'>
                                Return Home
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        <Footer />
        <CopyRights/>
        </>
    )
}

export default Error404
